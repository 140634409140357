import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from "./contexts/auth.jsx";
import Dashboard from './pages/dashboard/index.jsx';
import Login from './pages/login/index.jsx';
import AnaliseVendasMensal from './pages/vendas/analise/mensal/index.jsx';
import AnaliseVendasPorLoja from './pages/vendas/analise/porloja/index.jsx';
import AnaliseComparativoMes from './pages/vendas/analise/comparativo/index.jsx';
import Cadastro from './pages/cadastro/index.jsx';
import VendasCurvaABC from './pages/vendas/curva/index.jsx';
import ComprasCurvaABC from './pages/compras/curva/index.jsx';
import AnaliseComparativoMesCompra from './pages/compras/analise/comparativo/index.jsx';
import AnaliseComparativoCompraXVenda from './pages/compras/analise/compraxvenda/index.jsx';
import PDVFormaPagamento from './pages/vendas/pdv/forma-pagto/index.jsx';
import AnaliseVendasMetaDiaria from './pages/vendas/analise/metadiaria/index.jsx';
import VendasPorClientePorLoja from './pages/vendas/vendas-cliente-loja/index.jsx';
import AnaliseVendasMetaGrupo from './pages/vendas/analise/metagrupo/index.jsx';
import AnaliseVendasMetaVendedor from './pages/vendas/analise/metavendedor/index.jsx';
import VendasDetalhado from './pages/vendas/detalhado/index.jsx';
import AnaliseVendasClientesNovos from './pages/vendas/analise/clientesnovos/index.jsx';
import ContasAPagar from './pages/financeiro/contas-a-pagar/index.jsx';

function Rotas() {

    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if (!authenticated) {
            return <Navigate to="/login" />;
        }
        return children;
    }

    const Public = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);
        if (loading) {
            return <p>Carregando....</p>;
        }
        if (authenticated) {
            return <Navigate to="/" />;
        }
        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path='/' element={<Private><Dashboard /></Private>} />

                    <Route exact path='/vendas/curva-abc' element={<Private><VendasCurvaABC /></Private>} ></Route>

                    <Route exact path='/vendas/analise/mensal' element={<Private><AnaliseVendasMensal /></Private>} ></Route>
                    <Route exact path='/vendas/analise/comparativo' element={<Private><AnaliseComparativoMes /></Private>} ></Route>
                    <Route exact path='/vendas/analise/porloja' element={<Private><AnaliseVendasPorLoja /></Private>} ></Route>
                    <Route exact path='/vendas/analise/metadiaria' element={<Private><AnaliseVendasMetaDiaria /></Private>} ></Route>
                    <Route exact path='/vendas/analise/metagrupo' element={<Private><AnaliseVendasMetaGrupo /></Private>} ></Route>
                    <Route exact path='/vendas/analise/metavendedor' element={<Private><AnaliseVendasMetaVendedor /></Private>} ></Route>
                    <Route exact path='/vendas/analise/clientes-novos' element={<Private><AnaliseVendasClientesNovos /></Private>} ></Route>

                    <Route exact path='/vendas/relatorio/vendas-por-cliente' element={<Private><VendasPorClientePorLoja /></Private>} ></Route>
                    <Route exact path='/vendas/relatorio/detalhado' element={<Private><VendasDetalhado /></Private>} ></Route>

                    <Route exact path='/financeiro/relatorio/cotas-a-pagar' element={<Private><ContasAPagar /></Private>} ></Route>

                    <Route exact path='/compras/curva-abc' element={<Private><ComprasCurvaABC /></Private>} ></Route>

                    <Route exact path='/vendas/pdv/forma-pagamento' element={<Private><PDVFormaPagamento /></Private>} ></Route>

                    <Route exact path='/compras/analise/comparativo' element={<Private><AnaliseComparativoMesCompra /></Private>} ></Route>
                    <Route exact path='/compras/analise/compraxvenda' element={<Private><AnaliseComparativoCompraXVenda /></Private>} ></Route>

                    <Route path="*" element={<Public><Login /></Public>} />
                    <Route path='/login' element={<Public><Login /></Public>} />
                    <Route path='/cadastro' element={<Public><Cadastro /></Public>} />
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default Rotas;