import { useState } from 'react';
import InputMask from 'react-input-mask';
import { buscaCNPJ, validarEmail, validarOperador } from '../../services/api';
import Loader from '../../components/loader';
import { validaEmail } from "../../function/validaEmail.jsx";
import { useLocation, useNavigate } from 'react-router-dom';

const Cadastro = () => {

    const [codigo, setCodigo] = useState('');
    const [CNPJ, setCNPJ] = useState('');
    const [senha, setSenha] = useState('');
    const [cnpjDisabled, setCnpjDisabled] = useState(true);
    const [editCnpjDisabled, setEditCnpjDisabled] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [cnpjResult, setCnpjResult] = useState({ str_razaosocial: '' });
    const [operadorResult, setOperadorResult] = useState({ idcloud: '' });
    const [email, setEmail] = useState('');
    const [confirmarEmail, setConfirmarEmail] = useState('');
    const location = useLocation()

    const navigate = useNavigate();

    const handleChange = (e) => {
        const novoValor = e.target.value.replace(/\D/g, '');
        setCNPJ(novoValor);
        if (novoValor.length == 14) {
            setCnpjDisabled(false);
        }
        else {
            setCnpjDisabled(true);
        }
    };

    const handleBuscaCNPJ = (e) => {
        e.preventDefault();
        // Buscar CNPJ matriz no servidor
        setLoading(true);
        buscaCNPJF(CNPJ).then(() => {
            setLoading(false);
        });
    }

    const validarOperadorH = (e) => {
        e.preventDefault();
        if (codigo == '' || senha == '') {
            setError('Obrigatório preencher código e senha para prosseguir')
        }
        else {
            setLoading(true);
            validarOperadorF(codigo, senha, CNPJ).then(() => {
                setLoading(false);
            });
        }
    }

    const validarEmailH = (e) => {
        e.preventDefault();
        if (email == '' || confirmarEmail == '') {
            setError('Preenche corretamente os campos de e-mail');
        }
        else {
            if (validaEmail(email)) {
                if (email == confirmarEmail) {
                    setLoading(true);
                    validarEmailF(email, operadorResult.idcloud).then(() => {
                        setLoading(false);
                    });
                }
                else {
                    setError('E-mails digitados não combinam');
                }
            }
            else {
                setError('E-mail digitado não é válido');
            }
        }
    }

    const validarEmailF = async (Femail, FconfirmarEmail, Fidcloud) => {
        try {
            const response = await validarEmail(Femail, FconfirmarEmail, Fidcloud);
            if (response.data == 'SUCESSO') {
                const urlParams = new URLSearchParams(location.search);
                const Forigem = urlParams.get('origem');
                if (Forigem === '') {
                    navigate("/login?email= " + Femail);
                }
                else if (Forigem === 'faq') {
                    window.location.href = "https://faq.easysoftsistemas.com.br/login";
                }
                else {
                    navigate("/login");
                }
            }
            setError('');
        }
        catch (err) {
            setError(err.response.data);
        }
    }

    const validarOperadorF = async (Fcodigo, Fsenha, FCNPJ) => {
        try {
            const response = await validarOperador(Fcodigo, Fsenha, FCNPJ);
            setOperadorResult(response.data);
            setError('');
        }
        catch (err) {
            setError(err.response.data);
        }
    }

    const buscaCNPJF = async (cnpj) => {
        try {
            const response = await buscaCNPJ(cnpj);
            setCnpjResult(response.data);
            setCnpjDisabled(true);
            setEditCnpjDisabled(true);
            setError('');
        }
        catch (err) {
            setError(err.response.data);
        }
    }

    const handlerKeyDownCnpj = (e) => {
        setError('');
        var k = e.keyCode;
        if (k === 13) {
            if (CNPJ.length == 14) {
                e.preventDefault();
                handleBuscaCNPJ(e);
            }
        }
    }

    const handlerKeyDownConfirmarEmail = (e) => {
        setError();
        var k = e.keyCode;
        if (k === 13) {
            e.preventDefault();
            validarEmailH(e);
        }
    }

    const handlerKeyDownCodigo = (e) => {
        setError('');
        var k = e.keyCode;
        if (k === 13) {
            e.preventDefault();
            document.getElementById('senha').focus();
        }
    }

    const handlerKeyDownEmail = (e) => {
        setError();
        var k = e.keyCode;
        if (k === 13) {
            e.preventDefault();
            document.getElementById('confirmarEmail').focus();
        }
    }

    const handlerKeyDownSenha = (e) => {
        setError();
        var k = e.keyCode;
        if (k === 13) {
            e.preventDefault();
            if (codigo != '' && senha != '') {
                validarOperadorH(e);
            }
        }
    }

    const displayError = !error == true ? 'alert alert-danger visually-hidden' : 'alert alert-danger';

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-5 d-none d-lg-block bg-register2-image"></div>
                                    <div className="col-lg-7">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Cadastre seu primeiro acesso!</h1>
                                            </div>
                                            <div className={displayError} role="alert">
                                                {error}
                                            </div>
                                            <form className="user">
                                                <div className="form-group row">
                                                    <div className="col-sm-12 mb-sm-0 input-group ">
                                                        <InputMask
                                                            className="form-control form-control-user"
                                                            mask="99.999.999/9999-99"
                                                            value={CNPJ}
                                                            disabled={editCnpjDisabled}
                                                            onChange={handleChange}
                                                            placeholder="Digite o CNPJ da matriz"
                                                            onKeyDown={(e) => handlerKeyDownCnpj(e)}
                                                            autoFocus={true}
                                                        />
                                                        <button style={{ borderTopRightRadius: '10rem', borderBottomRightRadius: "10rem" }}
                                                            className="btn btn-primary pe-3"
                                                            disabled={cnpjDisabled}
                                                            onClick={handleBuscaCNPJ} >
                                                            <i className="fa-solid fa-magnifying-glass"></i>
                                                        </button>
                                                    </div>
                                                    {
                                                        (cnpjResult.str_razaosocial != '') &&
                                                        <div className="col-sm-12 mb-sm-0 input-group  mt-3">
                                                            <input
                                                                type="text"
                                                                readOnly={true}
                                                                disabled
                                                                className="form-control form-control-user"
                                                                onKeyDown={() => {setError('')}}
                                                                value={cnpjResult.str_razaosocial} />
                                                        </div>
                                                    }
                                                    {
                                                        (cnpjResult.str_razaosocial != '') &&
                                                        (operadorResult.idcloud == '') &&
                                                        <>

                                                            <div className="col-sm-6  mb-sm-0 input-group  mt-3">
                                                                <input
                                                                    type="text"
                                                                    placeholder='Código do operador'
                                                                    className="form-control form-control-user"
                                                                    onChange={(e) => setCodigo(e.target.value)}
                                                                    onKeyDown={(e) => handlerKeyDownCodigo(e)}
                                                                    value={codigo}
                                                                    id='codigo'
                                                                    autoFocus={true} />
                                                            </div>
                                                            <div className="col-sm-6  mb-sm-0 input-group  mt-3">
                                                                <input
                                                                    type="password"
                                                                    placeholder='Senha do operador'
                                                                    id='senha'
                                                                    className="form-control form-control-user"
                                                                    onChange={(e) => setSenha(e.target.value)}
                                                                    value={senha}
                                                                    onKeyDown={(e) => handlerKeyDownSenha(e)} />

                                                            </div>

                                                            <div className="col-sm-12 mb-sm-0 input-group  mt-3">
                                                                <button onClick={validarOperadorH} type='button' className="btn btn-primary btn-user btn-block">
                                                                    Validar
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        (operadorResult.idcloud != '') &&
                                                        <>
                                                            <div className="col-sm-12  mb-sm-0 input-group  mt-3">
                                                                <input
                                                                    type="text"
                                                                    disabled={true}
                                                                    className="form-control form-control-user"
                                                                    value={operadorResult.str_nome}
                                                                    onKeyDown={() => {setError('')}} />
                                                            </div>
                                                            <div className="col-sm-12  mb-sm-0 input-group  mt-3">
                                                                <input
                                                                    type="email"
                                                                    placeholder='Digite seu email'
                                                                    style={{ textTransform: 'lowercase' }}
                                                                    className="form-control form-control-user"
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    onKeyDown={(e) => handlerKeyDownEmail(e)}
                                                                    value={email}
                                                                    id='email'
                                                                    autoFocus={true} />
                                                            </div>
                                                            <div className="col-sm-12  mb-sm-0 input-group  mt-3">
                                                                <input
                                                                    type="email"
                                                                    placeholder='Confirme seu email'
                                                                    style={{ textTransform: 'lowercase' }}
                                                                    className="form-control form-control-user"
                                                                    onChange={(e) => setConfirmarEmail(e.target.value)}
                                                                    onKeyDown={(e) => handlerKeyDownConfirmarEmail(e)}
                                                                    value={confirmarEmail}
                                                                    id='confirmarEmail' />
                                                            </div>
                                                            <div className="col-sm-12 mb-sm-0 input-group  mt-3">
                                                                <button onClick={validarEmailH} type='button' className="btn btn-primary btn-user btn-block">
                                                                    Cadastrar
                                                                </button>
                                                            </div>

                                                        </>
                                                    }

                                                </div>
                                                {/* <div className="form-group">
                                                <input
                                                    type="password"
                                                    className="form-control form-control-user"
                                                    id="senha"
                                                    placeholder="********"
                                                    onChange={(e) => setSenha(e.target.value)}
                                                    value={senha} />
                                            </div>*/}

                                                <div className="text-center mt-3">
                                                    <a className="small" href="/login">Já possui conta? Faça seu login!</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Cadastro;