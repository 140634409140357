import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { parse, isValid } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import Loader from "../../../components/loader/index.jsx";
import { AuthContext } from "../../../contexts/auth.jsx";
import { vendasRelatorioPorCliente } from "../../../services/venda/relatorio.jsx";
import { api } from "../../../services/api.jsx";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Box } from '@mui/material';
import { ptBR } from '@mui/x-data-grid/locales';


const VendasPorClientePorLoja = () => {
    const [isLoading, setLoading] = useState(true);
    const { logout } = useContext(AuthContext);
    const [response, setResponse] = useState({ Lojas: [], Body: [] });
    const [selectTipoCompra, setSelectTipoCompra] = useState('Comprou');
    const [classStartDate, setClassStartDate] = useState('');
    const [styleStartDate, setStyleStartDate] = useState({ display: "none" });
    const [classEndDate, setClassEndDate] = useState('');
    const [styleEndDate, setStyleEndDate] = useState({ display: "none" });
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [startDate, setStartDate] = useState(getCurrentDate('01'));
    const [endDate, setEndDate] = useState(getCurrentDate);
    const [globalFilter, setGlobalFilter] = useState('');
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const handleSelectTipoCompra = (event) => {
        setSelectTipoCompra(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        validaDatas(event.target.value, endDate);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        validaDatas(startDate, event.target.value);
    };

    const validaDatas = (startDate1, endDate1) => {
        const parsedStartDate = parse(startDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedStartDate)) {
            setClassStartDate('');
            setStyleStartDate({ display: 'none' });
            setBtnDisabled(false);
        } else {
            setClassStartDate('is-invalid');
            setStyleStartDate({ display: 'block' });
            setBtnDisabled(true);
        }

        const parsedEndDate = parse(endDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedEndDate)) {
            setClassEndDate('');
            setStyleEndDate({ display: 'none' });
        } else {
            setClassEndDate('is-invalid');
            setStyleEndDate({ display: 'block' });
        }

        if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        } else {
            Navigate("/login");
        }
        vendasRelatorioPorClienteF().then(() => {
            setLoading(false);
        });
    }, []);

    const vendasRelatorioPorClienteF = async () => {
        try {
            var response = await vendasRelatorioPorCliente(formatarData(startDate, '00:00:00'), formatarData(endDate, '23:59:59'), selectTipoCompra);
            setResponse(response.data);
        } catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    };

    function formatarData(dataString, hora) {
        const partes = dataString.split('-');
        const ano = partes[0];
        const mes = partes[1];
        const dia = partes[2];
        return `${mes}/${dia}/${ano} ${hora}`;
    }

    function getCurrentDate(dia = '') {
        const currentDate = new Date();
        let day = dia ? dia : currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        return `${year}-${month}-${day}`;
    }

    const handlerFiltrar = () => {
        setLoading(true);
        vendasRelatorioPorClienteF().then(() => {
            setLoading(false);
        });
    };

    const exportToPDF = () => {
        const dataForPDF = response.Body.map(data => {
            const rowData = [
                data.codigo_cliente,
                data.nome_cliente,
                ...response.Lojas.map(loja => data[loja.n_codigoloja.toString()]),
                data.total
            ];
            return rowData;
        });

        const doc = new jsPDF('landscape');
        doc.setFontSize(16);
        doc.text('RELATÓRIO QUANTIDADE DE VENDAS POR CLIENTE POR LOJA', 14, 15);
        doc.autoTable({
            head: [
                [{ content: 'Código', styles: { halign: 'center' } }, { content: 'Cliente', styles: { halign: 'center' } },
                ...response.Lojas.map(loja => ({ content: loja.str_fantasia, styles: { halign: 'center' } })),
                { content: 'Total', styles: { halign: 'center' } }]
            ],
            body: dataForPDF,
            startY: 20
        });
        doc.save('tabela.pdf');
    };

    const columns = [
        { field: 'codigo_cliente', headerName: 'Código', width: 150 },
        { field: 'nome_cliente', headerName: 'Cliente', width: 200 },
        ...response.Lojas.map(loja => ({ field: loja.n_codigoloja.toString(), headerName: loja.str_fantasia, width: 150 })),
        { field: 'total', headerName: 'Total', width: 150 }
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'por-cliente-por-loja',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {isLoading && <Loader />}
            <div id="wrapper">
                <SideBar active="VendasPorClientePorLoja" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="date"
                                                        className={"form-control " + classStartDate}
                                                        id="startDate"
                                                        name="startDate"
                                                        value={startDate}
                                                        onChange={handleStartDateChange}
                                                    />
                                                    <div className="invalid-tooltip" style={styleStartDate}>
                                                        Data inválida
                                                    </div>
                                                </div>
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="date"
                                                        className={"form-control " + classEndDate}
                                                        id="endDate"
                                                        name="endDate"
                                                        value={endDate}
                                                        onChange={handleEndDateChange}
                                                    />
                                                    <div className="invalid-tooltip" style={styleEndDate}>
                                                        Data inválida
                                                    </div>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoCompra} onChange={handleSelectTipoCompra}>
                                                        <option value="ComprouEmUmaUnicaLoja">Comprou em uma loja</option>
                                                        <option value="ComprouEmMaisDeUmaLoja">Comprou em várias lojas</option>
                                                        <option value="Comprou">Comprou em uma ou várias lojas</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary" disabled={btnDisabled}>Filtrar</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12">
                                                    <div style={{ height: 600, width: '100%' }}>
                                                        <DataGrid
                                                            rows={response.Body}
                                                            columns={columns}
                                                            pageSize={10}
                                                            rowsPerPageOptions={[10]}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(model) => setFilterModel(model)}
                                                            getRowId={(row) => row.codigo_cliente}
                                                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                                            slots={{ toolbar: CustomToolbar }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default VendasPorClientePorLoja;