export const colors = [
    'rgba(255, 99, 132)',
    'rgba(54, 162, 235)',
    'rgba(255, 159, 64)',
    'rgba(75, 192, 192)',
    'rgba(153, 102, 255)',
    'rgba(128, 128, 128)',
    'rgba(255, 0, 0)',
    'rgba(0, 255, 0)',
    'rgba(0, 0, 255)',
    'rgba(255, 255, 0)',
    'rgba(0, 255, 255)',
    'rgba(128, 0, 0)',
    'rgba(0, 128, 0)',
    'rgba(0, 0, 128)',
    'rgba(255, 205, 86)',
    'rgba(128, 128, 0)',
    'rgba(128, 0, 128)',
    'rgba(0, 128, 128)',
    'rgba(192, 192, 192)',
    'rgba(255, 99, 71)',
    'rgba(60, 179, 113)',
    'rgba(123, 104, 238)',
    'rgba(238, 130, 238)',
    'rgba(255, 20, 147)',
    'rgba(139, 69, 19)',
    'rgba(255, 140, 0)',
    'rgba(70, 130, 180)',
    'rgba(240, 230, 140)',
    'rgba(244, 164, 96)',
    'rgba(127, 255, 212)',
    'rgba(218, 165, 32)',
    'rgba(199, 21, 133)',
    'rgba(106, 90, 205)',
    'rgba(255, 182, 193)',
    'rgba(255, 228, 196)',
    'rgba(210, 105, 30)',
    'rgba(173, 216, 230)',
    'rgba(0, 100, 0)',
    'rgba(72, 61, 139)',
    'rgba(0, 206, 209)',
];

export const colorsa = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(128, 128, 128, 0.2)',
    'rgba(255, 0, 0, 0.2)',
    'rgba(0, 255, 0, 0.2)',
    'rgba(0, 0, 255, 0.2)',
    'rgba(255, 255, 0, 0.2)',
    'rgba(0, 255, 255, 0.2)',
    'rgba(128, 0, 0, 0.2)',
    'rgba(0, 128, 0, 0.2)',
    'rgba(0, 0, 128, 0.2)',
    'rgba(255, 205, 86, 0.2)',
    'rgba(128, 128, 0, 0.2)',
    'rgba(128, 0, 128, 0.2)',
    'rgba(0, 128, 128, 0.2)',
    'rgba(192, 192, 192, 0.2)',
];

export const colorsh = [
    '#FF6384', // rgba(255, 99, 132, 1) => #FF6384
    '#36A2EB', // rgba(54, 162, 235, 1) => #36A2EB
    '#FF9F40', // rgba(255, 159, 64, 1) => #FF9F40
    '#4BC0C0', // rgba(75, 192, 192, 1) => #4BC0C0
    '#9966FF', // rgba(153, 102, 255, 1) => #9966FF
    '#808080', // rgba(128, 128, 128, 1) => #808080
    '#FF0000', // rgba(255, 0, 0, 1) => #FF0000
    '#00FF00', // rgba(0, 255, 0, 1) => #00FF00
    '#0000FF', // rgba(0, 0, 255, 1) => #0000FF
    '#FFFF00', // rgba(255, 255, 0, 1) => #FFFF00
    '#00FFFF', // rgba(0, 255, 255, 1) => #00FFFF
    '#800000', // rgba(128, 0, 0, 1) => #800000
    '#008000', // rgba(0, 128, 0, 1) => #008000
    '#000080', // rgba(0, 0, 128, 1) => #000080
    '#FFCD56', // rgba(255, 205, 86, 1) => #FFCD56
    '#808000', // rgba(128, 128, 0, 1) => #808000
    '#800080', // rgba(128, 0, 128, 1) => #800080
    '#008080', // rgba(0, 128, 128, 1) => #008080
    '#C0C0C0'  // rgba(192, 192, 192, 1) => #C0C0C0
]

export const colorsb = (t) => [
    'rgba(255, 99, 132, ' + t + ')',
    'rgba(54, 162, 235, ' + t + ')',
    'rgba(255, 159, 64, ' + t + ')',
    'rgba(75, 192, 192, ' + t + ')',
    'rgba(153, 102, 255, ' + t + ')',
    'rgba(128, 128, 128, ' + t + ')',
    'rgba(255, 0, 0, ' + t + ')',
    'rgba(0, 255, 0, ' + t + ')',
    'rgba(0, 0, 255, ' + t + ')',
    'rgba(255, 255, 0, ' + t + ')',
    'rgba(0, 255, 255, ' + t + ')',
    'rgba(128, 0, 0, ' + t + ')',
    'rgba(0, 128, 0, ' + t + ')',
    'rgba(0, 0, 128, ' + t + ')',
    'rgba(255, 205, 86, ' + t + ')',
    'rgba(128, 128, 0, ' + t + ')',
    'rgba(128, 0, 128, ' + t + ')',
    'rgba(0, 128, 128, ' + t + ')',
    'rgba(192, 192, 192, ' + t + ')',
];