export const convertReal = (value, casa = 2) => {
    if (value === undefined || value == 'NaN') {
        return '';
    }
    else {
        return value.toLocaleString('pt-BR', {
            minimumFractionDigits: casa,
            maximumFractionDigits: casa,
        })
    }
}