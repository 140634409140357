
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { AuthContext } from '../../contexts/auth';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { alterarMeta } from '../../services/venda/analise.jsx';
import Card from '../card/index.jsx';
import { convertReal } from '../../function/convertReal.jsx';

ChartJS.register(
    CategoryScale,
    LineElement,
    LinearScale,
    BarElement,
    ChartAnnotation,
    Title,
    Tooltip,
    Legend
);


const MetaDiaria = ({ res, setLoading, month, i, height, selectTipo, tipoMeta }) => {

    const [meta, setMeta] = useState();
    const [button, setButton] = useState('d-none');
    const { logout } = useContext(AuthContext);
    const [disabledmeta, setDisabledMed] = useState(false);

    useEffect(() => {
        setMeta(res.MetaDiaria);
    }, []);

    useEffect(() => {
        if (meta == res.MetaDiaria) {
            setButton('d-none');
        }
        else {
            setButton('')
        }
    }, [meta]);

    useEffect(() => {

    }, [selectTipo]);

    useEffect(() => {
        setMeta(res.MetaDiaria);
        if (selectTipo == 'Consolidado') {
            setDisabledMed(true);
        }
        else {
            setDisabledMed(false);
        }
    }, [res]);

    const handleSalvarMeta = () => {
        setButton('d-none');
        res.MetaDiaria = meta;

        alterarMetaF().then(() => {
            setLoading(false);
        });
    }

    const alterarMetaF = async () => {
        try {
            var response = await alterarMeta(formatarDataSemPontuacao(month) + '.' + tipoMeta, res.cnpj, meta);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    function formatarDataSemPontuacao(dataString) {
        // Dividir a string em ano e mês
        const [ano, mes] = dataString.split('-');

        // Remover o zero à esquerda do mês, se houver
        const mesSemZero = parseInt(mes, 10);

        // Formatar a data no formato desejado
        const dataFormatada = `${ano}${mesSemZero}`;

        return dataFormatada;
    }

    function average(ctx) {
        const values = ctx.chart.data.datasets[1].data; // Acessando os valores da segunda série (vendas)
        return values.reduce((a, b) => a + b, 0) / values.length;
    }

    return (
        <div className="card shadow mb-4">
            <div className="card-header">
                <div className="row">
                    <div className="col-xl-1 col-12">
                        <label className="col-form-label">Meta:</label>
                    </div>
                    <div className="col-xl-4 col-12 mb-2">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">R$</span>
                            <input disabled={disabledmeta} type="number" className="form-control" value={meta} onChange={(e) => setMeta(e.target.value)} />
                        </div>
                    </div>
                    <div className={"col-xl-2 col-12 mb-2  d-grid gap-2 " + button}>
                        <button className='btn btn-primary' type='button' onClick={handleSalvarMeta}>Salvar</button>
                    </div>
                    <div className="dropdown no-arrow col-xl-2 ms-auto col-12">
                        <a className="dropdown-toggle pointer btn btn-primary text-white d-grid gap-2" role="button" data-bs-toggle="collapse" data-bs-target={"#cardRepetido" + i} aria-expanded="true" aria-controls={"cardRepetido" + i}>
                            <b>+ info</b>
                        </a>
                    </div>
                </div>

            </div>
            <div className="card-body">
                <div className="row collapse hide justify-content-center" id={"cardRepetido" + i}>
                    <div className="col-xl-3 col-lg-6 col-6 p-1">
                        <Card
                            title='Venda s/ dev.'
                            text={convertReal(res.Totalizador.TotalItemVendasemdev, 0)}
                            icon='fas fa-arrow-up-right-dots'
                            color="cherry"
                            percent={convertReal(res.Totalizador.TotalDevolucao, 0)}
                            tips={"Total Devolução"}
                            hint="Total Vendas sem Devolução" />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-6 p-1">
                        <Card
                            title='Venda c/ dev.'
                            text={convertReal(res.Totalizador.TotalItemVenda, 0)}
                            icon='fas fa-store'
                            color="cherry"
                            percent={convertReal(res.Totalizador.TotalItemVendaComCusto, 0)}
                            tips="Total Venda com Custo"
                            hint="Total Venda com Devolução" />
                    </div>

                    <div className="col-xl-2 col-lg-6 col-6 p-1">
                        <Card
                            title='Custo'
                            text={convertReal(res.Totalizador.TotalItemCusto, 0)}
                            icon='fas fa-dollar-sign'
                            color="blue-dark"
                            percent="&nbsp;"
                            hint="" />
                    </div>
                    <div className="col-xl-2 col-lg-6 col-6 p-1">
                        <Card
                            title='Lucro'
                            text={convertReal(res.Totalizador.LucroValor, 0)}
                            icon='fas fa-scale-balanced'
                            color="green-dark"
                            percent={convertReal(res.Totalizador.Lucratividade, 1) + '%'}
                            tips="Lucratividade"
                            hint="" />
                    </div>
                    <div className="col-xl-2 col-lg-6 col-6 p-1">
                        <Card
                            title='Margem'
                            text={convertReal(res.Totalizador.LucroAplicado, 1) + '%'}
                            icon='fas fa-arrow-up-right-dots'
                            color="orange-dark"
                            percent="&nbsp;"
                            hint="Margem Aplicada" />
                    </div>
                </div>
                <div className="row justify-content-center d-flex align-items-center">
                    <div className="col-12" style={{ height: height, maxHeight: "500px" }}>
                        <Line plugins={[ChartAnnotation]}
                            options={
                                {
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: res.NomeLoja
                                        },
                                        tooltip: {
                                            mode: 'index',
                                            intersect: false,
                                        },
                                        annotation: {
                                            annotations: [{
                                                type: 'line',
                                                borderColor: 'red',
                                                borderWidth: 2,
                                                label: {
                                                    backgroundColor: 'rgba(255, 0, 0, 0.6)',
                                                    content: 'Meta: ' + convertReal(+meta, 0),
                                                    display: true
                                                },
                                                scaleID: 'y',
                                                value: meta
                                            },
                                            {
                                                type: 'line',
                                                borderColor: 'gray',
                                                borderDash: [6, 6],
                                                borderDashOffset: 0,
                                                borderWidth: 3,
                                                label: {
                                                    display: true,
                                                    content: (ctx) => 'Média: ' + convertReal(average(ctx), 0),
                                                    position: 'end'
                                                },
                                                scaleID: 'y',
                                                value: (ctx) => average(ctx).toFixed(0)
                                            }
                                            ]
                                        },
                                    },
                                }}
                            data={
                                {
                                    labels: res.Body.map((b, key) => (b.dia)),
                                    datasets: [
                                        {
                                            label: 'Meta',
                                            data: res.Body.map((b) => (meta)),
                                            borderColor: 'rgba(255, 0, 0, 1)',
                                            backgroundColor: 'rgba(255, 0, 0, 0.6)',
                                            borderWidth: 2,
                                            borderRadius: 5,
                                            pointRadius: 0
                                        },
                                        {
                                            label: 'Vendas',
                                            data: res.Body.map((b, key) => (b.n_total)),
                                            borderColor: 'rgba(54, 162, 235, 1)',
                                            backgroundColor: 'rgba(54, 162, 235, 0.6)',
                                            borderWidth: 2,
                                            borderRadius: 5,
                                        },
                                        {
                                            label: 'Média',
                                            data: res.Body.map((b) => ((ctx) => average(ctx))),
                                            borderColor: 'rgba(128, 128, 128, 1)',
                                            backgroundColor: 'rgba(128, 128, 128, 0.6)',
                                            borderWidth: 2,
                                            borderRadius: 5,
                                            pointRadius: 0
                                        },
                                    ],
                                }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MetaDiaria;