import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useContext, useEffect, useState } from "react";
import { api } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import { vendasAnalisePorLoja } from "../../../../services/venda/analise.jsx";
import Loader from "../../../../components/loader/index.jsx";
import { convertReal } from "../../../../function/convertReal.jsx";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Card from "../../../../components/card/index.jsx";
import { parse, isValid } from 'date-fns';
import { AuthContext } from "../../../../contexts/auth.jsx";
import { getCurrentDate } from "../../../../function/getCurrentDate.jsx";

ChartJS.register(
    CategoryScale,
    LineElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AnaliseVendasPorLoja = () => {

    const [isLoading, setLoading] = useState(true);
    const { logout } = useContext(AuthContext);
    const [response, setResponse] = useState({ content: [{ Body: { QD_ComCadastro: 0, QD_SemCadastro: 0, QD_ComCadastroAno: 0, QD_SemCadastroAno: 0 } }], Totalizador: {} });
    const [selectTipoData, setSelectTipoData] = useState('DataCaixa');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [startDate, setStartDate] = useState(getCurrentDate('01'));
    const [endDate, setEndDate] = useState(getCurrentDate);
    const [classStartDate, setClassStartDate] = useState('');
    const [styleStartDate, setStyleStartDate] = useState({ display: "none" });
    const [classEndDate, setClassEndDate] = useState('');
    const [styleEndDate, setStyleEndDate] = useState({ display: "none" });

    const handleSelectTipoData = (event) => {
        localStorage.setItem('tipoData', event.target.value);
        setSelectTipoData(event.target.value)
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }

        const tipoData = localStorage.getItem('tipoData');
        if (tipoData) {
            setSelectTipoData(tipoData);
        }
        else {
            localStorage.setItem('tipoData', 'DataCaixa');
            setSelectTipoData('DataCaixa');
        }

        vendasAnalisePorLojaF().then(() => {
            setLoading(false);
        });

    }, []);

    const vendasAnalisePorLojaF = async () => {
        try {
            var response = await vendasAnalisePorLoja(formatarData(startDate, '00:00:00'), formatarData(endDate, '23:59:59'), selectTipoData);
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }

    }

    function formatarData(dataString, hora) {
        const partes = dataString.split('-'); // Divide a string nos hífens
        const ano = partes[0]; // Obtém o ano
        const mes = partes[1]; // Obtém o mês
        const dia = partes[2]; // Obtém o dia
        // Retorna a data no formato desejado
        return `${mes}/${dia}/${ano} ${hora}`;
    }

    

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);

        validaDatas(event.target.value, endDate)
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);

        validaDatas(startDate, event.target.value);
    };

    const validaDatas = (startDate1, endDate1) => {
        const parsedStartDate = parse(startDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedStartDate)) {
            setClassStartDate('');
            setStyleStartDate({ display: 'none' });
            setBtnDisabled(false);
        }
        else {
            setClassStartDate('is-invalid');
            setStyleStartDate({ display: 'block' });
            setBtnDisabled(true);
        }

        const parsedEndDate = parse(endDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedEndDate)) {
            setClassEndDate('');
            setStyleEndDate({ display: 'none' });
        }
        else {
            setClassEndDate('is-invalid');
            setStyleEndDate({ display: 'block' });
        }

        if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
            setBtnDisabled(false);
        }
        else {
            setBtnDisabled(true);
        }

    }

    const handlerFiltrar = () => {
        setLoading(true);
        vendasAnalisePorLojaF().then(() => {
            setLoading(false);
        });
    }

    function getTotalByColumn(columnIndex, response) {
        let total = 0;
        response.content.forEach((item, index) => {
            if (index === columnIndex) {
                total += item.Body.QD_ComCadastro + item.Body.QD_SemCadastro;
            }
        });
        return total;
    }

    function getTotalByColumnAno(columnIndex, response) {
        let total = 0;
        response.content.forEach((item, index) => {
            if (index === columnIndex) {
                total += item.Body.QD_ComCadastroAno + item.Body.QD_SemCadastroAno;
            }
        });
        return total;
    }

    const calcularMediaTicketMedio = (jsonData) => {
        const totalItens = jsonData.content.length;
        let somaTicketMedio = 0;

        jsonData.content.forEach((item) => {
            somaTicketMedio += item.Body.n_ticket_medio;
        });

        const mediaTicketMedio = somaTicketMedio / totalItens;
        return convertReal(mediaTicketMedio, 2)
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="AnaliseVendasPorLoja" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="date"
                                                        className={"form-control " + classStartDate}
                                                        id="startDate"
                                                        name="startDate"
                                                        value={startDate}
                                                        onChange={handleStartDateChange}
                                                    />
                                                    <div className="invalid-tooltip" style={styleStartDate}>
                                                        Data inválida
                                                    </div>
                                                </div>
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="date"
                                                        className={"form-control " + classEndDate}
                                                        id="endDate"
                                                        name="endDate"
                                                        value={endDate}
                                                        onChange={handleEndDateChange}
                                                    />
                                                    <div className="invalid-tooltip" style={styleEndDate}>
                                                        Data inválida
                                                    </div>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option value="DataPedido">Data Pedido</option>
                                                        <option value="DataCaixa">Data Entrada no Caixa</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary" disabled={btnDisabled}>Filtrar</button>
                                                </div>
                                                <div className="mt-1 me-2 d-grid gap-2 col-xl-1 col-12 text-end dropdown no-arrow ms-auto">
                                                    <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample"} aria-expanded="true" aria-controls={"collapseWidthExample"}>
                                                        <b>+ info</b>
                                                    </a>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-body  collapse hide" id={"collapseWidthExample"}>
                                            <div className="row">
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda sem dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVendasemdev, 0)}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalDevolucao, 0)}
                                                        tips={"Total Devolução"}
                                                        hint="Total Vendas sem Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda com dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVenda, 0)}
                                                        icon='fas fa-store'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalItemVendaComCusto, 0)}
                                                        tips="Total Venda com Custo"
                                                        hint="Total Venda com Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Custo'
                                                        text={convertReal(response.Totalizador.TotalItemCusto, 0)}
                                                        icon='fas fa-dollar-sign'
                                                        color="blue-dark"
                                                        percent="&nbsp;"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Lucro'
                                                        text={convertReal(response.Totalizador.LucroValor, 0)}
                                                        icon='fas fa-scale-balanced'
                                                        color="green-dark"
                                                        percent={convertReal(response.Totalizador.Lucratividade, 1) + '%'}
                                                        tips="Lucratividade"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Margem '
                                                        text={convertReal(response.Totalizador.LucroAplicado, 1) + '%'}
                                                        icon='fas fa-percent'
                                                        color="cyan"
                                                        percent="&nbsp;"
                                                        tips=""
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Ticket Médio '
                                                        text={calcularMediaTicketMedio(response)}
                                                        icon='fas fa-house'
                                                        color="cyan"
                                                        percent="&nbsp;"
                                                        tips=""
                                                        hint="Ticket Médio" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div className="card shadow mb-4">
                                                <div className="card-body">
                                                    <div className="row justify-content-center d-flex align-items-center">
                                                        <div className="col-12" style={{ height: "36vh" }}>
                                                            <Line options={
                                                                {
                                                                    maintainAspectRatio: false,
                                                                    responsive: true,
                                                                    plugins: {
                                                                        legend: {
                                                                            position: 'top',
                                                                        },
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Relatório de ticket médio'
                                                                        },
                                                                        tooltip: {
                                                                            mode: 'index',
                                                                            intersect: false,
                                                                        },
                                                                    },
                                                                }}
                                                                data={
                                                                    {
                                                                        labels: response.content.map(item => item.NomeLoja),
                                                                        datasets: [
                                                                            {
                                                                                label: 'Ticket Médio',
                                                                                data: response.content.map(item => item.Body.n_ticket_medio),
                                                                                borderColor: 'rgb(255, 99, 132)',
                                                                                backgroundColor: 'rgba(255, 99, 132, 0.7)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            }
                                                                        ],
                                                                    }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div className="card shadow mb-4">
                                                <div className="card-body">
                                                    <div className="row justify-content-center d-flex align-items-center">
                                                        <div className="col-12" style={{ height: "36vh" }}>
                                                            <Line options={
                                                                {
                                                                    maintainAspectRatio: false,
                                                                    responsive: true,
                                                                    plugins: {
                                                                        legend: {
                                                                            position: 'top',
                                                                        },
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Vendas Com e Sem Cliente'
                                                                        },
                                                                        tooltip: {
                                                                            mode: 'index',
                                                                            intersect: false,
                                                                            callbacks: {
                                                                                footer: (tooltipItems) => {
                                                                                    if (tooltipItems.length > 0) {
                                                                                        const columnIndex = tooltipItems[0].dataIndex;
                                                                                        return `Total Ano Atual: ${getTotalByColumn(columnIndex, response)} \n` +
                                                                                            `Total Ano Anterior: ${getTotalByColumnAno(columnIndex, response)}`;
                                                                                    }
                                                                                    return '';
                                                                                },
                                                                            },
                                                                        },
                                                                    },

                                                                }}
                                                                data={
                                                                    {
                                                                        labels: response.content.map(item => item.NomeLoja),
                                                                        datasets: [
                                                                            {
                                                                                label: 'Com Cadastro Ano Atual',
                                                                                data: response.content.map(item => item.Body.QD_ComCadastro),
                                                                                borderColor: 'rgb(75, 192, 192)',
                                                                                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            },
                                                                            {
                                                                                label: 'Sem Cadastro Ano Atual',
                                                                                data: response.content.map(item => item.Body.QD_SemCadastro),
                                                                                borderColor: 'rgb(255, 99, 132)',
                                                                                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            },
                                                                            {
                                                                                label: 'Com Cadastro Ano Anterior',
                                                                                data: response.content.map(item => item.Body.QD_ComCadastroAno),
                                                                                borderColor: 'rgb(30, 120, 120)',
                                                                                backgroundColor: 'rgba(30, 120, 120, 0.6)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            },
                                                                            {
                                                                                label: 'Sem Cadastro Ano Anterior',
                                                                                data: response.content.map(item => item.Body.QD_SemCadastroAno),
                                                                                borderColor: 'rgb(190, 50, 80)',
                                                                                backgroundColor: 'rgba(190, 50, 80, 0.6)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            },
                                                                        ],
                                                                    }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div className="card shadow mb-4">
                                                <div className="card-body">
                                                    <div className="row justify-content-center d-flex align-items-center">
                                                        <div className="col-12" style={{ height: "36vh" }}>
                                                            <Line options={
                                                                {
                                                                    maintainAspectRatio: false,
                                                                    responsive: true,
                                                                    plugins: {
                                                                        legend: {
                                                                            position: 'top',
                                                                        },
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Lucratividade x Margem Aplicada'
                                                                        },
                                                                        tooltip: {
                                                                            mode: 'index',
                                                                            intersect: false,
                                                                        },
                                                                    },

                                                                }}
                                                                data={
                                                                    {
                                                                        labels: response.content.map(item => item.NomeLoja),
                                                                        datasets: [
                                                                            {
                                                                                label: 'Lucratividade',
                                                                                data: response.content.map(item => item.Body.Lucratividade),
                                                                                borderColor: 'rgba(54, 162, 235, 1)',
                                                                                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            },
                                                                            {
                                                                                label: 'Margem Aplicada',
                                                                                data: response.content.map(item => item.Body.LucroAplicado),
                                                                                borderColor: 'rgba(75, 192, 192, 1)',
                                                                                backgroundColor: 'rgba(75, 192, 192, 0.7)',
                                                                                borderWidth: 2,
                                                                                borderRadius: 5,
                                                                            },
                                                                        ],
                                                                    }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <div className="row" >
                                                <div className="col-lg-12" style={{ height: "80vh" }}>
                                                    <Bar plugins={[ChartDataLabels]}
                                                        options={
                                                            {
                                                                plugins: {
                                                                    title: {
                                                                        display: false,
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'top',
                                                                        labels: {
                                                                            filter: (legendItem, chartData) => {
                                                                                return legendItem.datasetIndex !== chartData.datasets.length - 1; // Oculta o último dataset ('Total')
                                                                            },
                                                                        },
                                                                    },
                                                                    tooltip: {
                                                                        mode: 'index',
                                                                        intersect: false,
                                                                        filter: (tooltipItem) => {
                                                                            return tooltipItem.dataset.label !== 'Total';
                                                                        },
                                                                        callbacks: {
                                                                            footer: (tooltipItems) => {
                                                                                let total = 0;

                                                                                tooltipItems.forEach((tooltipItem) => {
                                                                                    const value = tooltipItem.parsed.y || 0; // Valor do tooltipItem ou 0 se não definido
                                                                                    total += value; // Soma o valor ao total
                                                                                });

                                                                                let dev = response.content[tooltipItems[0].dataIndex].Body.devolucao;
                                                                                let totb = total + (dev * -1);

                                                                                return `Total: ${convertReal(total, 2)} \n` +
                                                                                    `Devolução: ${convertReal(dev, 2)} \n` +
                                                                                    `Total Bruto: ${convertReal(totb, 2)}`; // Retorna o total para o footer
                                                                            },
                                                                        },
                                                                    },
                                                                    datalabels: {
                                                                        color: '#222',
                                                                        font: {
                                                                            size: 12, // Defina o tamanho da fonte desejado
                                                                            weight: 'bold', // Defina o peso da fonte como negrito
                                                                        },
                                                                        textAlign: 'center',

                                                                        anchor: 'center', // Alinhamento do texto no centro da barra
                                                                        align: 'center',
                                                                        formatter: (value, context) => {
                                                                            const datasetArray = [];
                                                                            const datasetIndex = context.datasetIndex;
                                                                            const chartData = context.chart.data;
                                                                            const datasets = chartData.datasets;
                                                                            let sum = 0;

                                                                            context.chart.data.datasets.forEach((dataset) => {
                                                                                if (dataset.data[context.dataIndex] != undefined) {
                                                                                    datasetArray.push(dataset.data[context.dataIndex]);
                                                                                }
                                                                            });

                                                                            datasets.forEach(dataset => {
                                                                                sum += dataset.data[context.dataIndex];
                                                                            });

                                                                            const percentage = Math.round((value / sum) * 100) + '%';
                                                                            if (percentage == '0%') {
                                                                                if (context.datasetIndex === datasetArray.length - 1) {
                                                                                    return 'Total: ' + convertReal(sum, 0) + '\n\n';
                                                                                }
                                                                                else {
                                                                                    return ''
                                                                                }
                                                                            }
                                                                            else {
                                                                                return percentage;
                                                                            }
                                                                        },
                                                                    },

                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    y: {
                                                                        stacked: true,
                                                                    },
                                                                    x: {
                                                                        stacked: true,
                                                                        beginAtZero: true, // Começar o eixo y a partir de zero
                                                                        min: 0, // Definir o valor mínimo do eixo y como zero
                                                                        suggestedMax: Math.max(...response.content.map((key) => key.total)) + 100, // Definir o valor máximo sugerido do eixo y como o máximo valor de venda mais 100 (para garantir algum espaço adicional)
                                                                    }
                                                                },
                                                            }
                                                        }
                                                        data={
                                                            {
                                                                labels: response.content.map(item => item.NomeLoja),
                                                                datasets: [
                                                                    {
                                                                        label: 'Custo',
                                                                        data: response.content.map(item => item.Body.n_totalitem_custo),
                                                                        borderColor: 'rgb(255, 99, 132)',
                                                                        backgroundColor: 'rgba(255, 99, 132, 0.7)',
                                                                    },
                                                                    {
                                                                        label: 'Lucro',
                                                                        data: response.content.map(item => item.Body.n_lucro_valor),
                                                                        borderColor: 'rgb(75, 192, 192)',
                                                                        backgroundColor: 'rgba(75, 192, 192, 0.7)',
                                                                    },
                                                                    {
                                                                        label: 'Venda s/ Custo',
                                                                        data: response.content.map(item => item.Body.n_totalitem_vendasemcusto),
                                                                        borderColor: 'rgb(255, 159, 64)',
                                                                        backgroundColor: 'rgba(255, 159, 64, 0.7)',
                                                                    },
                                                                    {
                                                                        label: 'Total',
                                                                        data: response.content.map(item => 0),
                                                                    },
                                                                ],
                                                            }
                                                        } />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div >
            </div >
        </>
    )
}

export default AnaliseVendasPorLoja;