import { useContext, useEffect, useState } from "react";
import './style.css';
import { AuthContext } from "../../contexts/auth";

const SideBar = ({ active }) => {
    const [larguraTela, setLarguraTela] = useState(window.innerWidth);
    const [padrao, setPadrao] = useState('show');
    const { user, activeStore } = useContext(AuthContext);

    // Configuração do resize para ajustar o menu conforme a largura
    useEffect(() => {
        const handleResize = () => {
            setLarguraTela(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setPadrao('');
        } else {
            setPadrao('show');
        }
    }, [larguraTela]);

    // Função genérica para adicionar as classes `active` e `show`
    const isActive = (key) => active === key ? 'active show' : '';
    const isExpanded = (keys) => keys.includes(active) ? 'active show' : '';

    const Administrador = () => {
        return (
            <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion collapse ${padrao}`} id="accordionSidebar">
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                    <div className="sidebar-brand-icon">
                        <i className="fa-solid fa-chart-simple"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3">DASHBOARD</div>
                </a>
                <hr className="sidebar-divider my-0" />
                <li className={`nav-item ${isActive('Dashboard')}`}>
                    <a className="nav-link" href="/">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </a>
                </li>

                <hr className="sidebar-divider" />
                <div className="sidebar-heading">GRÁFICOS</div>

                <li className={`nav-item ${isExpanded(['VendasCurvaABC', 'AnaliseVendasMensal', 'AnaliseVendasPorLoja', 'AnaliseVendasComparativoMes', 'PDVFormaPagamento', 'AnaliseVendasMetaDiaria', 'AnaliseVendasMetaGrupo', 'AnaliseVendasMetaVendedor', 'AnaliseVendasClientesNovos'])}`}>
                    <a className="nav-link" data-bs-toggle="collapse" href="#collapseVendas" aria-expanded="false" aria-controls="collapseVendas">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>Vendas</span>
                    </a>
                    <div id="collapseVendas" className={`collapse ${isExpanded(['VendasCurvaABC', 'AnaliseVendasMensal', 'AnaliseVendasPorLoja', 'AnaliseVendasComparativoMes', 'PDVFormaPagamento', 'AnaliseVendasMetaDiaria', 'AnaliseVendasMetaGrupo', 'AnaliseVendasMetaVendedor', 'AnaliseVendasClientesNovos'])}`}>
                        <div className="collapse-inner m-0 nivel1">
                            <a className={`item nav-link ${isActive('VendasCurvaABC')}`} href="/vendas/curva-abc">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Curva ABC</span>
                            </a>
                        </div>
                        <div className="collapse-inner m-0">
                            <a className="nav-link" data-bs-toggle="collapse" href="#collapseVendasAnalise" aria-expanded="false" aria-controls="collapseVendasAnalise">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Análise</span>
                            </a>
                            <div id="collapseVendasAnalise" className={`collapse ${isExpanded(['AnaliseVendasMensal', 'AnaliseVendasPorLoja', 'AnaliseVendasComparativoMes', 'AnaliseVendasMetaDiaria', 'AnaliseVendasMetaGrupo', 'AnaliseVendasMetaVendedor', 'AnaliseVendasClientesNovos'])}`}>
                                <div className="collapse-inner">
                                    <a className={`collapse-item ${isActive('AnaliseVendasMensal')}`} href="/vendas/analise/mensal">Mensal</a>
                                    <a className={`collapse-item ${isActive('AnaliseVendasComparativoMes')}`} href="/vendas/analise/comparativo">Comparativo</a>
                                    <a className={`collapse-item ${isActive('AnaliseVendasPorLoja')}`} href="/vendas/analise/porloja">Por Loja</a>
                                    <a className={`collapse-item ${isActive('AnaliseVendasMetaDiaria')}`} href="/vendas/analise/metadiaria">Meta Total</a>
                                    <a className={`collapse-item ${isActive('AnaliseVendasMetaGrupo')}`} href="/vendas/analise/metagrupo">Meta Grupo</a>
                                    <a className={`collapse-item ${isActive('AnaliseVendasMetaVendedor')}`} href="/vendas/analise/metavendedor">Meta Vendedor</a>
                                    <a className={`collapse-item ${isActive('AnaliseVendasClientesNovos')}`} href="/vendas/analise/clientes-novos">Clientes Novos</a>
                                </div>
                            </div>
                        </div>
                        <div className="collapse-inner m-0">
                            <a className="nav-link" data-bs-toggle="collapse" href="#collapseVendasPDV" aria-expanded="false" aria-controls="collapseVendasPDV">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>PDV</span>
                            </a>
                            <div id="collapseVendasPDV" className={`collapse ${isExpanded(['PDVFormaPagamento'])}`}>
                                <div className="collapse-inner">
                                    <a className={`collapse-item ${isActive('PDVFormaPagamento')}`} href="/vendas/pdv/forma-pagamento">Formas de Pagto</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li className={`nav-item ${isExpanded(['AnaliseComprasComparativoMes', 'AnaliseComparativoCompraXVenda', 'ComprasCurvaABC'])}`}>
                    <a className="nav-link" data-bs-toggle="collapse" href="#collapseCompras" aria-expanded="false" aria-controls="collapseCompras">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>Compras</span>
                    </a>
                    <div id="collapseCompras" className={`collapse ${isExpanded(['AnaliseComprasComparativoMes', 'AnaliseComparativoCompraXVenda', 'ComprasCurvaABC'])}`}>
                        <div className="collapse-inner m-0 nivel1">
                            <a className={`item nav-link ${isActive('ComprasCurvaABC')}`} href="/compras/curva-abc">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Curva ABC</span>
                            </a>
                        </div>
                        <div className="collapse-inner m-0">
                            <a className="nav-link" data-bs-toggle="collapse" href="#collapseCompraAnalise" aria-expanded="false" aria-controls="collapseCompraAnalise">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Análise</span>
                            </a>
                            <div id="collapseCompraAnalise" className={`collapse ${isExpanded(['AnaliseComprasComparativoMes', 'AnaliseComparativoCompraXVenda'])}`}>
                                <div className="collapse-inner">
                                    <a className={`collapse-item ${isActive('AnaliseComprasComparativoMes')}`} href="/compras/analise/comparativo">Comparativo</a>
                                    <a className={`collapse-item ${isActive('AnaliseComparativoCompraXVenda')}`} href="/compras/analise/compraxvenda">CompraXVenda</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li className={`nav-item ${isExpanded(['PDVFormaPagamento'])}`}>
                    <a className="nav-link" data-bs-toggle="collapse" href="#collapsePDV" aria-expanded="false" aria-controls="collapsePDV">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>PDV</span>
                    </a>
                    <div id="collapsePDV" className={`collapse ${isExpanded(['PDVFormaPagamento'])}`}>
                        <div className="collapse-inner m-0 nivel1">
                            <a className={`item nav-link ${isActive('PDVFormaPagamento')}`} href="/vendas/pdv/forma-pagamento">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Forma Pagto</span>
                            </a>
                        </div>
                    </div>
                </li>

                <hr className="sidebar-divider d-none d-md-block" />
                <div className="sidebar-heading">RELATÓRIOS</div>

                <li className={`nav-item ${isExpanded(['VendasPorClientePorLoja', 'VendasDetalhado'])}`}>
                    <a className="nav-link" data-bs-toggle="collapse" href="#collapseVendasRelatorio" aria-expanded="false" aria-controls="collapseVendasRelatorio">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>Vendas</span>
                    </a>
                    <div id="collapseVendasRelatorio" className={`collapse ${isExpanded(['VendasPorClientePorLoja', 'VendasDetalhado'])}`}>
                        <div className="collapse-inner m-0 nivel1">
                            <a className={`item nav-link ${isActive('VendasPorClientePorLoja')}`} href="/vendas/relatorio/vendas-por-cliente">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Por Cliente</span>
                            </a>
                        </div>
                        <div className="collapse-inner m-0 nivel1">
                            <a className={`item nav-link ${isActive('VendasDetalhado')}`} href="/vendas/relatorio/detalhado">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Detalhado</span>
                            </a>
                        </div>
                    </div>
                </li>
                <li className={`nav-item ${isExpanded(['ContasAPagar'])}`}>
                    <a className="nav-link" data-bs-toggle="collapse" href="#collapseFinanceiroRelatorio" aria-expanded="false" aria-controls="collapseFinanceiroRelatorio">
                        <i className="fas fa-fw fa-folder"></i>
                        <span>Financeiro</span>
                    </a>
                    <div id="collapseFinanceiroRelatorio" className={`collapse ${isExpanded(['ContasAPagar'])}`}>
                        <div className="collapse-inner m-0 nivel1">
                            <a className={`item nav-link ${isActive('ContasAPagar')}`} href="/financeiro/relatorio/cotas-a-pagar">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Contas a Pagar</span>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        );
    };

    const Usuario = () => (
        <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion collapse ${padrao}`} id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                <div className="sidebar-brand-icon">
                    <i className="fa-solid fa-chart-simple"></i>
                </div>
                <div className="sidebar-brand-text mx-3">DASHBOARD</div>
            </a>
            <hr className="sidebar-divider d-none d-md-block" />
        </ul>
    );

    return (
        <>
            {user[activeStore].adm === 'S' ? <Administrador /> : (user[activeStore].usuario === 'S' ? <Usuario /> : <></>)}
        </>
    );
};

export default SideBar;
