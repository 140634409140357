import React, { useEffect } from "react";
import SideBar from "../../components/sidebar/index.jsx";
import TopBar from "../../components/topbar/index.jsx";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { api } from "../../services/api.jsx";
import { Navigate } from "react-router-dom";

const Dashboard = () => {

    useEffect(() => {

        withReactContent(Swal).fire({
            title: <b>Seja bem-vindo ao EasyDash!</b>,
            text: 'Informamos que o sistema ainda está em construção. Durante este período, desfrute do acesso completo gratuitamente até o dia 31/12/2024. Agradecemos por utilizar nosso sistema',
            icon: 'warning'
        })
    }, []);

    return (
        <div id="wrapper">
            <SideBar active="Dashboard" />
            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">
                    <TopBar />

                    {/* CONTEUDO DA PAGINA */}
                    <div className="container-fluid">
                        <h1 className="h3 mb-4 text-gray-800">Dashboard</h1>
                    </div>
                    {/* FIM CONTEUDO DA PAGINA */}

                </div>
            </div>
        </div>
    )
}

export default Dashboard;