import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, createSession } from '../services/api.jsx';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [activeStore, setActiveStore] = useState(0);

    useEffect(() => {
        try {
            api.defaults.headers.Authorization = `Basic dGVzdHNlcnZlcjp0ZXN0c2VydmVy`;
            const recoveredUser = localStorage.getItem('user');
            const token = JSON.parse(localStorage.getItem('token'));
            if (!Array.isArray(token)) {
                if (!token == '') {
                    logout();
                }
            }
            else {
                setActiveStore(localStorage.getItem('active'));
                if (recoveredUser) {
                    setUser(JSON.parse(recoveredUser));
                    api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
                }
            }
        } catch (error) {
            logout();
        }

        setLoading(false);
    }, []);

    const login = async (email, password) => {
        try {
            const response = await createSession(email, password);

            if (response.data.user) {
                const loggedUser = response.data.user;
                const token = response.data.token;
                let lib = 0;

                loggedUser.map((log, key) => {

                    if (log.adm == 'S' || log.usuario == 'S') {
                        if (lib == 0) {
                            localStorage.setItem('active', key);
                            setActiveStore(key);
                            lib = 1;
                        }
                    }
                });

                if (lib == 1) {
                    localStorage.setItem('user', JSON.stringify(loggedUser));
                    localStorage.setItem('token', JSON.stringify(token));

                    api.defaults.headers.Authorization = `Bearer ${token[0]}`;

                    setUser(loggedUser);

                    navigate("/");
                }
                else {
                    setError('Seu usuário não está liberado para acesso a nenhuma loja');
                    navigate("/login");
                }
            }
        }
        catch (err) {
            setError(err.response.data);
        }
    }

    const logout = () => {
        setUser(null);
        setError("");
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("active");
        api.defaults.headers.Authorization = `Basic dGVzdHNlcnZlcjp0ZXN0c2VydmVy`;
        navigate("/login");
    }

    return (
        <AuthContext.Provider value={{ authenticated: !!user, user, loading, error, login, logout, setLoading, activeStore, setActiveStore, setError }}>
            {children}
        </AuthContext.Provider>
    );
}

