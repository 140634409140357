import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
} from 'chart.js';
import { useContext, useEffect, useState } from "react";
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import { api } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import { vendasAnaliseMensal } from "../../../../services/venda/analise.jsx";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors, colorsa, colorsb } from "../../../../assets/colors/colors.jsx";
import Loader from "../../../../components/loader/index.jsx";
import YearFilter from "../../../../components/yearfilter/index.jsx";
import { convertReal } from "../../../../function/convertReal.jsx";
import { AuthContext } from "../../../../contexts/auth.jsx";
import Card from "../../../../components/card/index.jsx";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Filler,
    Title,
    Tooltip,
    Legend
);

const AnaliseVendasMensal = () => {

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState({
        content: [], Totalizador: {
            Lucratividade: 0, LucroAplicado: 0, LucroValor: 0, TotalItemCusto: 0, TotalItemVenda: 0, TotalItemVendaComCusto: 0
        }, Titulo: []
    });
    const [stacked, setStacked] = useState(false);
    const { logout } = useContext(AuthContext);
    const [selectTipoGrafico, setSelectTipoGrafico] = useState('bar');
    const [selectTipoData, setSelectTipoData] = useState('DataCaixa');

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleSelectTipoData = (event) => {
        localStorage.setItem('tipoData', event.target.value);
        setSelectTipoData(event.target.value)
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }

        const tipoData = localStorage.getItem('tipoData');
        if (tipoData) {
            setSelectTipoData(tipoData);
        }
        else {
            localStorage.setItem('tipoData', 'DataCaixa');
            setSelectTipoData('DataCaixa');
        }
        vendasAnaliseMensalF(selectedYear).then(() => {
            setLoading(false);
        });

    }, []);

    function addLeadingZero(number) { return number < 10 ? '0' + number : number }

    const vendasAnaliseMensalF = async (ano) => {
        try {
            let datainicio, datafim, firstDay, lastDay;

            firstDay = new Date(ano, 0, 1);
            lastDay = new Date(ano, 11, 31);

            datainicio = addLeadingZero(firstDay.getMonth() + 1) + '/' + addLeadingZero(firstDay.getDate()) + '/' + firstDay.getFullYear() + ' 00:00:00';
            datafim = addLeadingZero(lastDay.getMonth() + 1) + '/' + addLeadingZero(lastDay.getDate()) + '/' + lastDay.getFullYear() + ' 23:59:59';

            var response = await vendasAnaliseMensal(datainicio, datafim, selectTipoData);
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const getTotalByColumn = (columnIndex) => {
        let total = 0;
        response.content.forEach(item => {
            total += item.Body[columnIndex];
        });
        return convertReal(total, 2);
    };

    const activeMonths = response.Titulo.filter((month, index) => {
        return response.content.some(item => item.Body[index] !== 0);
    });

    // Filtrar os dados para incluir apenas os meses ativos
    const filteredData = response.content.map(item => ({
        NomeLoja: item.NomeLoja,
        Body: item.Body.filter((value, index) => activeMonths.includes(response.Titulo[index]))
    }));

    const filteredLabels = response.Titulo.filter(month => activeMonths.includes(month));

    const handleSelectChange = (event) => {
        const value = event.target.value === 'true'; // Converta o valor da option para booleano
        setStacked(value); // Atualize a variável stacked
    };

    const handlerFiltrar = () => {
        setLoading(true);
        vendasAnaliseMensalF(selectedYear).then(() => {
            setLoading(false);
        });
    }

    const optionsMensal = {
        scales: {
            y: {
                stacked: stacked
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'COMPARATIVO MENSAL DE FATURAMENTO',
                font: {
                    size: 18,
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    footer: (tooltipItems) => {
                        if (tooltipItems.length > 0) {
                            const columnIndex = tooltipItems[0].dataIndex;
                            return `Total: ${getTotalByColumn(columnIndex)}`;
                        }
                        return '';
                    },
                },
            },
        },
    };

    const dataMensal = {
        labels: filteredLabels,
        datasets: filteredData.map((item, index) => {
            return ({
                fill: stacked,
                stacked: stacked,
                label: item.NomeLoja,
                data: item.Body,
                borderColor: colors[index],
                borderWidth: 3,
                backgroundColor: colorsb(0.7)[index]
            })
        })
    };

    const optionsTicket = {
        scales: {
            y: {
                stacked: stacked
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "RELATÓRIO DE TICKET MÉDIO",
                font: {
                    size: 18,
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    const dataTicket = {
        labels: filteredLabels,
        datasets: response.content.map((item, index) => {
            return ({
                fill: stacked,
                stacked: stacked,
                label: item.NomeLoja,
                data: item.QdadeVendas.map((qdade, i) => {
                    var valor;
                    if (qdade == 0) {
                        valor = '0';
                    }
                    else {
                        valor = convertReal(item.Body[i] / qdade, 0);
                    }
                    return valor;
                }),
                borderColor: colors[index],
                borderWidth: 3,
                backgroundColor: colorsb(0.7)[index]
            })
        })
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="AnaliseVendasMensal" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">

                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <YearFilter selectedYear={selectedYear} onChange={handleYearChange} />
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2 col-12 d-grid gap-2">
                                                    <select value={stacked.toString()} onChange={handleSelectChange} className='form-select'>
                                                        <option value="false">Separado Por Loja</option>
                                                        <option value="true">Somar Lojas</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option value="DataPedido">Data Pedido</option>
                                                        <option value="DataCaixa">Data Entrada no Caixa</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoGrafico} onChange={(e) => setSelectTipoGrafico(e.target.value)}>
                                                        <option value="bar" selected={true}>Gráfico Barras</option>
                                                        <option value="line">Gráfico Linhas</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary">Filtrar</button>
                                                </div>
                                                <div className="me-2 mt-1  dropdown no-arrow col-xl-1 col-12 d-grid gap-2 ms-auto">
                                                    <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample"} aria-expanded="true" aria-controls={"collapseWidthExample"}>
                                                        <b>+ info</b>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="row collapse hide justify-content-center" id={"collapseWidthExample"}>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda sem dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVendasemdev, 0)}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalDevolucao, 0)}
                                                        tips={"Total Devolução"}
                                                        hint="Total Vendas sem Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda com dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVenda, 0)}
                                                        icon='fas fa-store'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalItemVendaComCusto, 0)}
                                                        tips="Total Venda com Custo"
                                                        hint="Total Venda com Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Custo'
                                                        text={convertReal(response.Totalizador.TotalItemCusto, 0)}
                                                        icon='fas fa-dollar-sign'
                                                        color="blue-dark"
                                                        percent="&nbsp;"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Lucro'
                                                        text={convertReal(response.Totalizador.LucroValor, 0)}
                                                        icon='fas fa-scale-balanced'
                                                        color="green-dark"
                                                        percent={convertReal(response.Totalizador.Lucratividade, 1) + "%"}
                                                        tips="Lucratividade"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Margem'
                                                        text={convertReal(response.Totalizador.LucroAplicado, 1) + "%"}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="orange-dark"
                                                        percent="&nbsp;"
                                                        hint="Margem Aplicada" />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="col-xl-4">
                                                    <Doughnut plugins={[ChartDataLabels]}
                                                        data={{
                                                            labels: ['Custo', 'Lucro', 'Venda s/ Custo'],
                                                            datasets: [
                                                                {
                                                                    data: [response.Totalizador.TotalItemCusto,
                                                                    response.Totalizador.LucroValor,
                                                                    response.Totalizador.TotalItemVenda - response.Totalizador.TotalItemVendaComCusto],
                                                                    backgroundColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)'],
                                                                },
                                                            ],
                                                        }}
                                                        options={
                                                            {
                                                                cutout: '50%',
                                                                rotation: 270,
                                                                circumference: 180,
                                                                maintainAspectRatio: false,
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {
                                                                            const sum = ctx.dataset.data.reduce((acc, data) => acc + data, 0);
                                                                            const percentage = ((value * 100) / sum).toFixed(0);
                                                                            if (percentage <= 1) {
                                                                                return ''
                                                                            }
                                                                            else {
                                                                                return percentage + '%'
                                                                            }
                                                                        },
                                                                        color: '#fff',
                                                                        labels: {
                                                                            title: { font: { size: '16' } }
                                                                        }
                                                                    },
                                                                },
                                                            }
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '120%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            textAlign: 'center',
                                                            fontSize: '1.3rem',
                                                            color: "#858796"
                                                        }}
                                                    >
                                                        R$ {convertReal(response.Totalizador.TotalItemVenda, 0)}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mt-5" />
                                            <div className="row" >
                                                <div className="col-lg-12 mt-4 mb-4" style={{ height: "70vh", maxHeight: "500px" }}>

                                                    {selectTipoGrafico === 'bar' ? (
                                                        <Bar
                                                            options={optionsMensal}
                                                            data={dataMensal} />
                                                    ) : (
                                                        <Line
                                                            options={optionsMensal}
                                                            data={dataMensal} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-lg-12 mt-4 mb-4" style={{ height: "80vh", maxHeight: "500px" }}>
                                                    {selectTipoGrafico === 'bar' ? (
                                                        <Bar
                                                            options={optionsTicket}
                                                            data={dataTicket} />
                                                    ) : (
                                                        <Line
                                                            options={optionsTicket}
                                                            data={dataTicket} />
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default AnaliseVendasMensal;