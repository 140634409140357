import axios from "axios";

export const api = axios.create({
    // baseURL : 'http://localhost:9005',
    //baseURL : 'http://200.150.203.85:9005/',
    baseURL : 'https://dashboardapi.easysoftsistemas.com.br',
});

export const createSession = async (email, password) => {
    return api.post('/sessions/dashboard', { email, password });
}

export const buscaCNPJ = async (cnpj) => {
    return api.get('/sessions/cnpj', { params: { cnpj } });
}

export const validarOperador = async (codigo, senha, cnpj) => {
    return api.get('/sessions/valida/operador', { params: { codigo, senha, cnpj } });
}

export const validarEmail = async (email, idCloud) => {
    return api.post('/sessions/valida/email', { email, idCloud });
}

export const getGrupoTable = async () => {
    return api.get('/tables/grupo');
}

export * from "./venda/curva_abc.jsx";
export * from "./compra/curva_abc.jsx";
export * from "./venda/analise.jsx";
export * from "./compra/analise.jsx";
export * from "./venda/pdv.jsx";
export * from "./financeiro/contas_a_pagar.jsx";