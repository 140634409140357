import { api } from "../api.jsx";

export const vendasAnaliseMensal = async (datainicio, datafim, tipodata) => {
    return api.get('/vendas/analises/pormes', { params: { datainicio, datafim, tipodata} });
}

export const vendasAnalisePorLoja = async (datainicio, datafim, tipodata) => {
    return api.get('/vendas/analises/porloja', { params: { datainicio, datafim, tipodata} });
}

export const vendasAnaliseComparativoMes = async (datainicio, datafim, tipoconsulta, tipodata) => {
    return api.get('/vendas/analises/comparames', { params: { datainicio, datafim, tipoconsulta, tipodata} });
}

export const vendasAnaliseMetaDiaria = async (datainicio, datafim, tipoconsulta, tipodata) => {
    return api.get('/vendas/analises/pordiaxmeta', { params: { datainicio, datafim, tipoconsulta, tipodata} });
}
export const vendasAnaliseMetaGrupo = async (datainicio, datafim, tipoconsulta, tipodata, idgrupo) => {
    return api.get('/vendas/analises/pordiaxmetaxgrupo', { params: { datainicio, datafim, tipoconsulta, tipodata, idgrupo} });
}

export const vendasAnaliseMetaVendedor = async (datainicio, datafim, tipoconsulta, tipodata) => {
    return api.get('/vendas/analises/pordiaxmetaxvendedor', { params: { datainicio, datafim, tipoconsulta, tipodata} });
}

export const alterarMeta = async (periodo, cnpjlocal, valor) => {
    return api.put('/vendas/analises/meta', { periodo, cnpjlocal, valor });
}

export const vendasAnaliseClientesNovos = async (datainicio, datafim) => {
    return api.get('/vendas/analises/clientesnovos', { params: { datainicio, datafim} });
}