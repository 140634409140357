import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";
import { useContext, useEffect, useState } from "react";
import { api } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import { comprasAnaliseCompraXVenda } from "../../../../services/compra/analise.jsx";
import Loader from "../../../../components/loader/index.jsx";
import YearFilter from "../../../../components/yearfilter/index.jsx";
import { AuthContext } from "../../../../contexts/auth.jsx";
import { addLeadingZero } from "../../../../function/addLeadingZero.jsx";
import { colors, colorsa, colorsb } from "../../../../assets/colors/colors.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AnaliseComparativoCompraXVenda = () => {

    const [isLoading, setLoading] = useState(true);
    const [selectTipo, setSelectTipo] = useState('PorLoja');
    const [response, setResponse] = useState({
        lojas: [{ NomeLoja: '', Body: { n_total_compra_pelo_custo: [], n_total_venda_pelo_custo: [] } }],
        Titulo: [],
    });
    const [colRelatorio, setColRelatorio] = useState('col-xl-6 col-lg-6 col-md-12');
    const [height, setHeight] = useState('35vh');
    const [selectTipoData, setSelectTipoData] = useState('DataCaixa');

    const { logout } = useContext(AuthContext);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const handleYearChange = (year) => {
        setSelectedYear(year);
        setLoading(true);
        comprasAnaliseCompraXVendaF(year).then(() => {
            setLoading(false);
        });
    };

    const handleSelectTipoData = (event) => { 
        localStorage.setItem('tipoData', event.target.value);
        setSelectTipoData(event.target.value) 
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }

        const tipoData = localStorage.getItem('tipoData');
        if (tipoData) {
            setSelectTipoData(tipoData);
        }
        else {
            localStorage.setItem('tipoData', 'DataCaixa');
            setSelectTipoData('DataCaixa');
        }
        comprasAnaliseCompraXVendaF(selectedYear).then(() => {
            setLoading(false);
        });

    }, []);

    const comprasAnaliseCompraXVendaF = async (ano) => {
        try {
            let datainicio, datafim, firstDay, lastDay;

            firstDay = new Date(ano, 0, 1);
            lastDay = new Date(ano, 11, 31);

            datainicio = addLeadingZero(firstDay.getMonth() + 1) + '/' + addLeadingZero(firstDay.getDate()) + '/' + firstDay.getFullYear() + ' 00:00:00';
            datafim = addLeadingZero(lastDay.getMonth() + 1) + '/' + addLeadingZero(lastDay.getDate()) + '/' + lastDay.getFullYear() + ' 23:59:59';

            var response = await comprasAnaliseCompraXVenda(datainicio, datafim, selectTipo, selectTipoData);
            if (selectTipo == 'Consolidado' || response.data.lojas.length == 1) {
                setColRelatorio('col-xl-12 col-lg-12 col-md-12');
                setHeight('70vh');
            }
            else {
                setColRelatorio('col-xl-6 col-lg-6 col-md-12');
                setHeight('35vh')
            }

            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const handlerFiltrar = () => {
        setLoading(true);
        comprasAnaliseCompraXVendaF(selectedYear).then(() => {
            setLoading(false);
        });
    }

    const activeMonths = response.Titulo.filter((month, index) => {
        return response.lojas.some(loja => loja.Body.n_total_venda_pelo_custo[index] !== 0);
    });

    // Filtrar os títulos para incluir apenas os meses ativos
    const filteredLabels = response.Titulo.filter(month => activeMonths.includes(month));

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="AnaliseComparativoCompraXVenda" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <YearFilter selectedYear={selectedYear} onChange={handleYearChange} />
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipo} onChange={(e) => setSelectTipo(e.target.value)}>
                                                        <option defaultValue value="PorLoja">Por Loja</option>
                                                        <option value="Consolidado">Consolidado</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option defaultValue value="DataPedido">Data Pedido</option>
                                                        <option value="DataCaixa">Data Entrada no Caixa</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary">Filtrar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <center><h5><b>COMPARATIVO DE COMPRAS X VENDA PELO PREÇO DE CUSTO</b></h5></center>
                                            <div className="row" >
                                                {/* style={{ height: "70vh" }} */}
                                                {
                                                    response.lojas.map((res, key) => {
                                                        return (
                                                            <div className={colRelatorio} key={key}>
                                                                <div className="card shadow mb-4" >
                                                                    <div className="card-body">
                                                                        <div className="col-12" style={{ height: height }}>
                                                                            <Bar
                                                                                data={{
                                                                                    labels: filteredLabels.map(title => title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()),
                                                                                    datasets: [
                                                                                        {
                                                                                            label: 'COMPRA',
                                                                                            data: res.Body.n_total_compra_pelo_custo.map((value, key) => value),
                                                                                            fill: true,
                                                                                            borderColor: colors[0],
                                                                                            backgroundColor: colorsb(0.4)[0],
                                                                                            borderWidth: 2,
                                                                                            borderRadius: 2
                                                                                        },
                                                                                        {
                                                                                            label: 'VENDA',
                                                                                            data: res.Body.n_total_venda_pelo_custo.map((value, key) => value),
                                                                                            fill: true,
                                                                                            borderColor: colors[3],
                                                                                            backgroundColor: colorsb(0.4)[3],
                                                                                            borderWidth: 2,
                                                                                            borderRadius: 2
                                                                                        }
                                                                                    ]
                                                                                }}
                                                                                options={{
                                                                                    maintainAspectRatio: false,
                                                                                    responsive: true,
                                                                                    plugins: {
                                                                                        title: {
                                                                                            text: res.NomeLoja,
                                                                                            display: true,
                                                                                            font: {
                                                                                                size: 16
                                                                                            }
                                                                                        },
                                                                                        tooltip: {
                                                                                            mode: 'index',
                                                                                            intersect: false
                                                                                        },
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default AnaliseComparativoCompraXVenda;