import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './style.css';

const Card = ({ title, text, icon, color, percent, tips, hint }) => {

    const TextNoHint = () => {
        if (hint !== '') {
            return (
            <OverlayTrigger
                placement="bottom"
                overlay={< Tooltip id="tooltip-bottom" > {hint}</Tooltip >} >
                <span>{text}</span>
            </OverlayTrigger >);

        }
        else {
            return text;
        }
    }

    return (
        <div className={"card l-bg-" + color}>
            <div className="card-statistic-3 p-2 m-1">
                <div className="card-icon card-icon-large"><i className={icon}></i></div>
                <div className="mb-2">
                    <p className="card-title mb-0 text-white f-11">{title}</p>
                </div>
                <div className="row align-items-center d-flex">
                    <div className="col-12">
                        <p className="text-white d-flex align-items-center mb-0 ">
                            <TextNoHint />
                        </p>
                    </div>
                    <div className="col-12 text-right text-white ">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip-bottom">{tips}</Tooltip>} >
                            <span>{percent}</span>
                        </OverlayTrigger>
                    </div>
                </div>
                {/* <div className="progress mt-1 " data-height="8" style={{height: "8px"}}>
                                                                    <div className="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{width: '25%'}}></div>
                                                                </div> */}
            </div>
        </div>
    );
}

export default Card;