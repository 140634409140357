import { api } from "../api.jsx";

export const comprasTopProduto = async (datainicio, datafim, top, tipoconsulta) => {
    return api.get('/compras/top/produto', { params: { datainicio, datafim, top, tipoconsulta } });
}

export const comprasTopGrupo = async (datainicio, datafim, top, tipoconsulta) => {
    return api.get('/compras/top/grupo', { params: { datainicio, datafim, top, tipoconsulta } });
}

export const comprasTopSubGrupo = async (datainicio, datafim, top, tipoconsulta) => {
    return api.get('/compras/top/subgrupo', { params: { datainicio, datafim, top, tipoconsulta } });
}

export const comprasTopMarca = async (datainicio, datafim, top, tipoconsulta) => {
    return api.get('/compras/top/marca', { params: { datainicio, datafim, top, tipoconsulta } });
}

export const comprasTopFornecedor = async (datainicio, datafim, top, tipoconsulta) => {
    return api.get('/compras/top/fornecedor', { params: { datainicio, datafim, top, tipoconsulta } });
}

export const comprasCurvaABC = async (datainicio, datafim, top, tipoconsulta, tipoInfo) => {
    return api.get('/compras/top/' + tipoInfo, { params: { datainicio, datafim, top, tipoconsulta } });
}