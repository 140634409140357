import React from 'react';

const YearFilter = ({ selectedYear, onChange }) => {
    const currentYear = new Date().getFullYear();

    // Gerar opções de anos desde o ano atual até 5 anos atrás
    const years = [];
    for (let year = currentYear; year >= currentYear - 5; year--) {
        years.push(year);
    }

    const handleChange = (event) => {
        const selectedYear = parseInt(event.target.value);
        onChange(selectedYear);
    };

    return (
        <select className='form-select' value={selectedYear} onChange={handleChange}>
            {years.map((year) => (
                <option key={year} value={year}>
                    {year}
                </option>
            ))}
        </select>
    );
};

export default YearFilter;
