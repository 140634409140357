import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";


import { useContext, useEffect, useState } from "react";
import { api, getGrupoTable } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import { vendasAnaliseMetaDiaria, vendasAnaliseMetaGrupo } from "../../../../services/venda/analise.jsx";
import Loader from "../../../../components/loader/index.jsx";
import { convertReal } from "../../../../function/convertReal.jsx";
import { getCurrentMonth } from "../../../../function/getCurrentMonth.jsx";
import Card from "../../../../components/card/index.jsx";
import { AuthContext } from "../../../../contexts/auth.jsx";
import MetaDiaria from "../../../../components/metaDiaria/index.jsx";


const AnaliseVendasMetaGrupo = () => {

    const [isLoading, setLoading] = useState(true);
    const { logout } = useContext(AuthContext);
    const [selectTipo, setSelectTipo] = useState('PorLoja');
    const [response, setResponse] = useState({
        content: [], Totalizador: {
            TotalItemVenda: 0,

        }
    });
    const [month, setMonth] = useState(getCurrentMonth());
    const [colRelatorio, setColRelatorio] = useState('col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12');
    const [height, setHeight] = useState('40vh');
    const [selectTipoData, setSelectTipoData] = useState('DataCaixa');
    const [selectGrupo, setSelectGrupo] = useState(0);
    const [grupos, setGrupos] = useState([]);

    const handleSelectTipo = (event) => { setSelectTipo(event.target.value) }
    const handleSelectGrupo = (event) => { setSelectGrupo(event.target.value) }

    const handleSelectTipoData = (event) => {
        localStorage.setItem('tipoData', event.target.value);
        setSelectTipoData(event.target.value)
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }

        const tipoData = localStorage.getItem('tipoData');
        if (tipoData) {
            setSelectTipoData(tipoData);
        }
        else {
            localStorage.setItem('tipoData', 'DataCaixa');
            setSelectTipoData('DataCaixa');
        }

        // Obter grupos para combo
        getGrupoTableF();

        vendasAnaliseMetaGrupoF().then(() => {
            setLoading(false);
        });

    }, []);

    const getGrupoTableF = async () => {
        var response = await getGrupoTable();
        setGrupos(response.data);
    }

    const vendasAnaliseMetaGrupoF = async () => {
        try {
            if (selectTipo == 'Consolidado') {
                setColRelatorio('col-12');
                setHeight('70vh');
            }
            else {
                setColRelatorio('col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12');
                setHeight('40vh')
            }
            var response = await vendasAnaliseMetaGrupo(formatarData(month, '01', '00:00:00'), formatarData(month, 0, '23:59:59'), selectTipo, selectTipoData, selectGrupo);
            setResponse(response.data);

        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }

    }

    function formatarData(dataString, dias, horas) {
        // Dividir a string em ano e mês
        const [ano, mes] = dataString.split('-');
        let dia;

        // Criar um objeto Date com o ano e mês, usando o último dia do mês
        const data = new Date(ano, mes, 0);

        // Extrair o dia, mês e ano da data
        if (dias == 0) {
            dia = data.getDate();
        }
        else {
            dia = dias;
        }

        const mesFormatado = data.getMonth() + 1; // Lembrando que os meses começam do zero, então adicionamos 1 para ficar no formato convencional
        const anoFormatado = data.getFullYear();

        // Formatar a data no formato desejado
        const dataFormatada = `${mesFormatado}/${dia}/${anoFormatado} ${horas}`;

        return dataFormatada;
    }

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handlerFiltrar = () => {
        setLoading(true);
        vendasAnaliseMetaGrupoF().then(() => {
            setLoading(false);
        });
    }

    let i = 1;

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="AnaliseVendasMetaGrupo" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="month"
                                                        className="form-control"
                                                        value={month}
                                                        onChange={handleMonthChange}
                                                    />
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectGrupo} onChange={handleSelectGrupo}>
                                                        {grupos.map((grupo) => (
                                                            <option key={grupo.n_codigogrupo} value={grupo.n_codigogrupo}>
                                                                {grupo.str_descricao}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipo} onChange={handleSelectTipo}>
                                                        <option defaultValue value="PorLoja">Por Loja</option>
                                                        <option value="Consolidado">Consolidado</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option value="DataPedido">Data Pedido</option>
                                                        <option value="DataCaixa">Data Entrada no Caixa</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary">Filtrar</button>
                                                </div>
                                                <div className="mt-1 me-2 d-grid gap-2 col-xl-1 ms-auto col-12 text-end dropdown no-arrow ">
                                                    <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample"} aria-expanded="true" aria-controls={"collapseWidthExample"}>
                                                        <b>+ info</b>
                                                    </a>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-body  collapse hide" id={"collapseWidthExample"}>
                                            <div className="row justify-content-center">

                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda sem dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVendasemdev, 0)}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalDevolucao, 0)}
                                                        tips={"Total Devolução"}
                                                        hint="Total Vendas sem Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda com dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVenda, 0)}
                                                        icon='fas fa-store'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalItemVendaComCusto, 0)}
                                                        tips="Total Venda com Custo"
                                                        hint="Total Venda com Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Custo'
                                                        text={convertReal(response.Totalizador.TotalItemCusto, 0)}
                                                        icon='fas fa-dollar-sign'
                                                        color="blue-dark"
                                                        percent="&nbsp;"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Lucro'
                                                        text={convertReal(response.Totalizador.LucroValor, 0)}
                                                        icon='fas fa-scale-balanced'
                                                        color="green-dark"
                                                        percent={convertReal(response.Totalizador.Lucratividade, 1) + '%'}
                                                        tips="Lucratividade"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Margem'
                                                        text={convertReal(response.Totalizador.LucroAplicado, 1) + '%'}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="orange-dark"
                                                        percent="&nbsp;"
                                                        hint="Margem Aplicada" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {

                                            response.content.map((res, key) => {
                                                i++;
                                                return (
                                                    <div className={colRelatorio} key={key}>
                                                        <MetaDiaria
                                                            res={res}
                                                            setLoading={setLoading}
                                                            month={month}
                                                            i={i}
                                                            height={height}
                                                            selectTipo={selectTipo}
                                                            tipoMeta={"GRUPO." + res.IdGrupo}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div >
            </div >
        </>
    )
}

export default AnaliseVendasMetaGrupo;