import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { useContext, useEffect, useState } from "react";
import { api } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import { vendasAnaliseComparativoMes } from "../../../../services/venda/analise.jsx";
import Loader from "../../../../components/loader/index.jsx";
import YearFilter from "../../../../components/yearfilter/index.jsx";
import { AuthContext } from "../../../../contexts/auth.jsx";
import { Line, Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { convertReal } from "../../../../function/convertReal.jsx";
import { colors, colorsa, colorsb } from "../../../../assets/colors/colors.jsx";
import Card from "../../../../components/card/index.jsx";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    ChartAnnotation,
    Filler,
    Title,
    Tooltip,
    Legend
);
const AnaliseComparativoMes = () => {

    const [isLoading, setLoading] = useState(true);
    const [selectTipo, setSelectTipo] = useState('PorLoja');
    const [selectTipoData, setSelectTipoData] = useState('DataCaixa');
    const [selectTipoGrafico, setSelectTipoGrafico] = useState('bar');
    const [response, setResponse] = useState({
        lojas: [{ NomeLoja: '', Body: { n_dif_mes_anterior: [], n_dif_mes_anterior_porc: [], n_total_venda_mes_anterior: [] } }],
        Titulo: [],
        Totalizador: {
            Lucratividade: 0, LucroAplicado: 0, LucroValor: 0, TotalItemCusto: 0, TotalItemVenda: 0, TotalItemVendaComCusto: 0
        }
    });
    const { logout } = useContext(AuthContext);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleSelectTipoData = (event) => {
        localStorage.setItem('tipoData', event.target.value);
        setSelectTipoData(event.target.value)
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }

        const tipoData = localStorage.getItem('tipoData');
        if (tipoData) {
            setSelectTipoData(tipoData);
        }
        else {
            localStorage.setItem('tipoData', 'DataCaixa');
            setSelectTipoData('DataCaixa');
        }
        vendasAnaliseComparativoMesF(selectedYear).then(() => {
            setLoading(false);
        });

    }, []);

    function addLeadingZero(number) { return number < 10 ? '0' + number : number }

    const vendasAnaliseComparativoMesF = async (ano) => {
        try {
            let datainicio, datafim, firstDay, lastDay;

            firstDay = new Date(ano, 0, 1);
            lastDay = new Date(ano, 11, 31);

            datainicio = addLeadingZero(firstDay.getMonth() + 1) + '/' + addLeadingZero(firstDay.getDate()) + '/' + firstDay.getFullYear() + ' 00:00:00';
            datafim = addLeadingZero(lastDay.getMonth() + 1) + '/' + addLeadingZero(lastDay.getDate()) + '/' + lastDay.getFullYear() + ' 23:59:59';

            var response = await vendasAnaliseComparativoMes(datainicio, datafim, selectTipo, selectTipoData);
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }
    const activeMonths = response.Titulo.filter((month, index) => {
        return response.lojas.some(loja => loja.Body.n_total_venda_mes[index] !== 0);
    });

    const filteredLabels = response.Titulo.filter(month => activeMonths.includes(month));

    const activeMonthsAno = response.Titulo.filter((month, index) => {
        return response.lojas.some(loja => loja.Body.n_total_venda_mes[index] !== 0);
    });

    const filteredLabelsAno = response.Titulo.filter(month => activeMonthsAno.includes(month));

    const handlerFiltrar = () => {
        setLoading(true);
        vendasAnaliseComparativoMesF(selectedYear).then(() => {
            setLoading(false);
        });
    }

    const chartData = {
        labels: filteredLabels.map(title => title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()),
        datasets: response.lojas.map((content, key) => {
            return [
                {
                    label: `${content.NomeLoja} - Mês Anterior`,
                    data: content.Body.n_total_venda_mes_anterior,
                    fill: false,
                    borderColor: colorsb(0.6)[key],
                    backgroundColor: colorsb(0.6)[key],
                    borderDash: [5, 5],
                }
                , {
                    label: `${content.NomeLoja} - Este Mês`,
                    data: content.Body.n_total_venda_mes,
                    fill: false,
                    borderColor: colors[key],
                    backgroundColor: colors[key],
                },
            ];
        }).flat()
    };

    const chartDataAno = {
        labels: filteredLabelsAno.map(title => title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()),
        datasets: response.lojas.map((content, key) => {
            return [
                {
                    label: `${content.NomeLoja} - Mês Ano Anterior`,
                    data: content.Body.n_total_venda_ano_passado,
                    fill: false,
                    borderColor: colorsb(0.6)[key],
                    backgroundColor: colorsb(0.6)[key],
                    borderDash: [5, 5],
                }
                , {
                    label: `${content.NomeLoja} - Este Mês`,
                    data: content.Body.n_total_venda_mes,
                    fill: false,
                    borderColor: colors[key],
                    backgroundColor: colors[key],
                },
            ];
        }).flat()
    };

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,


        plugins: {
            title: {
                text: "COMPARAÇÃO DAS VENDAS COM O MÊS ANTERIOR",
                display: true,
                font: {
                    size: 18
                }
            },
            tooltip: {
                interaction: {
                    mode: 'nearest'
                },
                callbacks: {
                    label: function (context) {
                        const lojaIndex = Math.floor(context.datasetIndex / 2);
                        const monthIndex = context.dataIndex;
                        const loja = response.lojas[lojaIndex];
                        const Body = loja.Body;

                        const currentMonthSales = Body.n_total_venda_mes[monthIndex];
                        const previousMonthSales = Body.n_total_venda_mes_anterior[monthIndex];
                        const difference = Body.n_dif_mes_anterior[monthIndex];
                        const percentageDifference = Body.n_dif_mes_anterior_porc[monthIndex];
                        return [
                            `${context.dataset.label.split('-')[0]}`,
                            `Mês Anterior: ${convertReal(previousMonthSales, 0)}`,
                            `Mês Atual: ${convertReal(currentMonthSales, 0)}`,
                            `Variação: ${convertReal(difference, 0)} (${convertReal(percentageDifference, 1)}%)`
                        ];
                    }
                }
            },
        }
    };

    const chartOptionsAno = {
        
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                text: "COMPARAÇÃO DAS VENDAS COM O ANO ANTERIOR",
                display: true,
                font: {
                    size: 18
                }
            },
            tooltip: {
                interaction: {
                    mode: 'nearest'
                },
                callbacks: {
                    label: function (context) {
                        const lojaIndex = Math.floor(context.datasetIndex / 2);
                        const monthIndex = context.dataIndex;
                        const loja = response.lojas[lojaIndex];
                        const Body = loja.Body;

                        const currentMonthSales = Body.n_total_venda_mes[monthIndex];
                        const previousMonthSales = Body.n_total_venda_ano_passado[monthIndex];
                        const difference = Body.n_dif_mes_ano_anterior[monthIndex];
                        const percentageDifference = Body.n_dif_mes_ano_anterior_porc[monthIndex];
                        return [
                            `${context.dataset.label.split('-')[0]}`,
                            `Mês Ano Anterior: ${convertReal(previousMonthSales, 0)}`,
                            `Mês Ano Atual: ${convertReal(currentMonthSales, 0)}`,
                            `Variação: ${convertReal(difference, 0)} (${convertReal(percentageDifference, 1)}%)`
                        ];
                    }
                }
            },
        }
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="AnaliseVendasComparativoMes" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <YearFilter selectedYear={selectedYear} onChange={handleYearChange} />
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipo} onChange={(e) => setSelectTipo(e.target.value)}>
                                                        <option defaultValue value="PorLoja">Por Loja</option>
                                                        <option value="Consolidado">Consolidado</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option value="DataPedido">Data Pedido</option>
                                                        <option value="DataCaixa">Data Entrada no Caixa</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoGrafico} onChange={(e) => setSelectTipoGrafico(e.target.value)}>
                                                        <option value="bar" selected={true}>Gráfico Barras</option>
                                                        <option value="line">Gráfico Linhas</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary">Filtrar</button>
                                                </div>
                                                <div className="me-2 mt-1  dropdown no-arrow col-xl-1 col-12 d-grid gap-2 ms-auto">
                                                    <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample"} aria-expanded="true" aria-controls={"collapseWidthExample"}>
                                                        <b>+ info</b>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row collapse hide justify-content-center" id={"collapseWidthExample"}>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda sem dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVendasemdev, 0)}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalDevolucao, 0)}
                                                        tips={"Total Devolução"}
                                                        hint="Total Vendas sem Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Venda com dev.'
                                                        text={convertReal(response.Totalizador.TotalItemVenda, 0)}
                                                        icon='fas fa-store'
                                                        color="cherry"
                                                        percent={convertReal(response.Totalizador.TotalItemVendaComCusto, 0)}
                                                        tips="Total Venda com Custo"
                                                        hint="Total Venda com Devolução" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Custo'
                                                        text={convertReal(response.Totalizador.TotalItemCusto, 0)}
                                                        icon='fas fa-dollar-sign'
                                                        color="blue-dark"
                                                        percent="&nbsp;"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Lucro'
                                                        text={convertReal(response.Totalizador.LucroValor, 0)}
                                                        icon='fas fa-scale-balanced'
                                                        color="green-dark"
                                                        percent={convertReal(response.Totalizador.Lucratividade, 1) + "%"}
                                                        tips="Lucratividade"
                                                        hint="" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-6">
                                                    <Card
                                                        title='Margem'
                                                        text={convertReal(response.Totalizador.LucroAplicado, 1) + "%"}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="orange-dark"
                                                        percent="&nbsp;"
                                                        hint="Margem Aplicada" />
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-lg-12" style={{ height: "70vh", maxHeight: "500px" }}>
                                                    {selectTipoGrafico === 'bar' ? (
                                                        <Bar
                                                            data={chartData}
                                                            options={chartOptions}
                                                        />
                                                    ) : (
                                                        <Line
                                                            data={chartData}
                                                            options={chartOptions}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12" style={{ height: "70vh", maxHeight: "500px" }}>
                                                    {selectTipoGrafico === 'bar' ? (
                                                        <Bar
                                                            data={chartDataAno}
                                                            options={chartOptionsAno}
                                                        />
                                                    ) : (
                                                        <Line
                                                            data={chartDataAno}
                                                            options={chartOptionsAno}
                                                        />
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default AnaliseComparativoMes;