export function getCurrentDate(dia = '') {
    const currentDate = new Date();
    let day;

    if (dia == '') {
        day = currentDate.getDate().toString().padStart(2, '0');
    }
    else {
        day = dia;
    }

    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Mês é base zero, então adicionamos 1
    const year = currentDate.getFullYear();
    return `${year}-${month}-${day}`;
} 