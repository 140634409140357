

const Loader = () => {
    return (
        <div className='loading'>
            <div className="spinner-border" style={{ width: "4rem", height: "4rem", color: "#4f6dd8" }}>
                <span className="visually-hidden">Loading...</span>
            </div>

        </div>
    )
}

export default Loader;