import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
} from 'chart.js';
import { useContext, useEffect, useState } from "react";
import { Line, Bar} from 'react-chartjs-2';
import { api } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import { vendasAnaliseClientesNovos } from "../../../../services/venda/analise.jsx";
import { colors, colorsa, colorsb } from "../../../../assets/colors/colors.jsx";
import Loader from "../../../../components/loader/index.jsx";
import YearFilter from "../../../../components/yearfilter/index.jsx";
import { convertReal } from "../../../../function/convertReal.jsx";
import { AuthContext } from "../../../../contexts/auth.jsx";
import Card from "../../../../components/card/index.jsx";
import { addLeadingZero } from "../../../../function/addLeadingZero.jsx";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Filler,
    Title,
    Tooltip,
    Legend
);

const AnaliseVendasClientesNovos = () => {

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState({
        content: [], consolidado: [], Titulo: []
    });
    const { logout } = useContext(AuthContext);
    const [selectTipoGrafico, setSelectTipoGrafico] = useState('bar');

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }

        vendasAnaliseClientesNovosF(selectedYear).then(() => {
            setLoading(false);
        });

    }, []);

    const vendasAnaliseClientesNovosF = async (ano) => {
        try {
            let datainicio, datafim, firstDay, lastDay;

            firstDay = new Date(ano, 0, 1);
            lastDay = new Date(ano, 11, 31);

            datainicio = addLeadingZero(firstDay.getMonth() + 1) + '/' + addLeadingZero(firstDay.getDate()) + '/' + firstDay.getFullYear() + ' 00:00:00';
            datafim = addLeadingZero(lastDay.getMonth() + 1) + '/' + addLeadingZero(lastDay.getDate()) + '/' + lastDay.getFullYear() + ' 23:59:59';

            var response = await vendasAnaliseClientesNovos(datainicio, datafim, 'DataCaixa');
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const getTotalByColumn = (columnIndex) => {
        let total = 0;
        response.content.forEach(item => {
            total += item.Body[columnIndex];
        });
        return convertReal(total, 0);
    };

    const activeMonths = response.Titulo.filter((month, index) => {
        return response.content.some(item => item.Body[index] !== 0);
    });

    // Filtrar os dados para incluir apenas os meses ativos
    const filteredData = response.content.map(item => ({
        NomeLoja: item.NomeLoja,
        Body: item.Body.filter((value, index) => activeMonths.includes(response.Titulo[index]))
    }));

    const filteredLabels = response.Titulo.filter(month => activeMonths.includes(month));

    const handlerFiltrar = () => {
        setLoading(true);
        vendasAnaliseClientesNovosF(selectedYear).then(() => {
            setLoading(false);
        });
    }

    const optionsMensal = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'CADASTRO DE CLIENTES NOVOS',
                font: {
                    size: 18,
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    footer: (tooltipItems) => {
                        if (tooltipItems.length > 0) {
                            const columnIndex = tooltipItems[0].dataIndex;
                            return `Total: ${getTotalByColumn(columnIndex)}`;
                        }
                        return '';
                    },
                },
            },
        },
    };

    const dataMensal = {
        labels: filteredLabels,
        datasets: filteredData.map((item, index) => {
            return ({
                label: item.NomeLoja,
                data: item.Body,
                borderColor: colors[index],
                borderWidth: 3,
                backgroundColor: colorsb(0.7)[index]
            })
        })
    };

    const optionsTicket = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "GRÁFICO CONSOLIDADO",
                font: {
                    size: 18,
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    const dataTicket = {
        labels: filteredLabels,
        datasets: [{
            label: 'TODAS AS LOJAS',
            data: response.consolidado,
            borderColor: colors[1],
            borderWidth: 3,
            backgroundColor: colorsb(0.7)[1]
        }],
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="AnaliseVendasClientesNovos" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">

                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <YearFilter selectedYear={selectedYear} onChange={handleYearChange} />
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoGrafico} onChange={(e) => setSelectTipoGrafico(e.target.value)}>
                                                        <option value="bar" selected={true}>Gráfico Barras</option>
                                                        <option value="line">Gráfico Linhas</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary">Filtrar</button>
                                                </div>
                                                <div className="me-2 mt-1  dropdown no-arrow col-xl-1 col-12 d-grid gap-2 ms-auto">
                                                    <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample"} aria-expanded="true" aria-controls={"collapseWidthExample"}>
                                                        <b>+ info</b>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="row collapse hide justify-content-center" id={"collapseWidthExample"}>
                                                <div className="col-xl-4 col-lg-6 col-6">
                                                    <Card
                                                        title='Total novos clientes de todo periodo'
                                                        text={convertReal(response.consolidado.reduce((acumulador, valorAtual) => acumulador + valorAtual, 0), 0)}
                                                        icon='fas fa-arrow-up-right-dots'
                                                        color="cherry"
                                                        hint="Somatoria de todas as lojas" />
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-lg-12 mt-4 mb-4" style={{ height: "70vh" }}>
                                                    {selectTipoGrafico === 'bar' ? (
                                                        <Bar
                                                            options={optionsMensal}
                                                            data={dataMensal} />
                                                    ) : (
                                                        <Line
                                                            options={optionsMensal}
                                                            data={dataMensal} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 mt-4 mb-4" style={{ height: "50vh" }}>
                                                    {selectTipoGrafico === 'bar' ? (
                                                        <Bar
                                                            options={optionsTicket}
                                                            data={dataTicket} />
                                                    ) : (
                                                        <Line
                                                            options={optionsTicket}
                                                            data={dataTicket} />
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default AnaliseVendasClientesNovos;