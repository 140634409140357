import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { parse, isValid } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import Loader from "../../../components/loader/index.jsx";
import { AuthContext } from "../../../contexts/auth.jsx";
import { vendasRelatorioDetalhado } from "../../../services/venda/relatorio.jsx";
import { api } from "../../../services/api.jsx";
import { DEFAULT_GRID_AUTOSIZE_OPTIONS, DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { getCurrentMonth } from '../../../function/getCurrentMonth.jsx';
import { format } from 'date-fns';


const VendasDetalhado = () => {
    const [isLoading, setLoading] = useState(true);
    const { logout } = useContext(AuthContext);
    const [response, setResponse] = useState({ Lojas: [], Body: [] });
    const [selectTipoData, setSelectTipoData] = useState('DataCaixa');
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [month, setMonth] = useState(getCurrentMonth());

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handleSelectTipoData = (event) => {
        localStorage.setItem('tipoData', event.target.value);
        setSelectTipoData(event.target.value)
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        } else {
            Navigate("/login");
        }

        const tipoData = localStorage.getItem('tipoData');
        if (tipoData) {
            setSelectTipoData(tipoData);
        }
        else {
            localStorage.setItem('tipoData', 'DataCaixa');
            setSelectTipoData('DataCaixa');
        }

        vendasRelatorioDetalhadoF().then(() => {
            setLoading(false);
        });
    }, []);

    const vendasRelatorioDetalhadoF = async () => {
        try {
            var response = await vendasRelatorioDetalhado(formatarData(month, '01', '00:00:00'), formatarData(month, 0, '23:59:59'), selectTipoData);
            setResponse(response.data);
        } catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    };

    function formatarData(dataString, dias, horas) {
        // Dividir a string em ano e mês
        const [ano, mes] = dataString.split('-');
        let dia;

        // Criar um objeto Date com o ano e mês, usando o último dia do mês
        const data = new Date(ano, mes, 0);

        // Extrair o dia, mês e ano da data
        if (dias == 0) {
            dia = data.getDate();
        }
        else {
            dia = dias;
        }

        const mesFormatado = data.getMonth() + 1; // Lembrando que os meses começam do zero, então adicionamos 1 para ficar no formato convencional
        const anoFormatado = data.getFullYear();

        // Formatar a data no formato desejado
        const dataFormatada = `${mesFormatado}/${dia}/${anoFormatado} ${horas}`;

        return dataFormatada;
    }

    const handlerFiltrar = () => {
        setLoading(true);
        vendasRelatorioDetalhadoF().then(() => {
            setLoading(false);
        });
    };

    const columns = [
        { field: 'idcloud', headerName: 'ID' },
        { field: 'dh_entrou_caixa', headerName: 'Data', width: 170 },
        { field: 'cc', headerName: 'Venda', width: 120 },
        { field: 'n_codigoloja', headerName: 'Cod Loja' },
        { field: 'str_fantasia', headerName: 'Fantasia', width: 150 },
        { field: 'str_codigocliente', headerName: 'Cod Cliente' },
        { field: 'str_cliente', headerName: 'Cliente', width: 200 },
        { field: 'n_codigovendedor', headerName: 'Cod Vendedor' },
        { field: 'str_nome', headerName: 'Vendedor', width: 120 },
        { field: 'str_descricao', headerName: 'Produto', width: 200 },
        { field: 'str_tamanho', headerName: 'Tamanho' },
        { field: 'str_unidade', headerName: 'Unidade' },
        { field: 'n_precocusto', headerName: 'Custo', type: 'number' },
        { field: 'n_precovendido_liquido', headerName: 'Unitário Líquido', type: 'number' },
        { field: 'n_qdadevendida', headerName: 'Qtd Venda', type: 'number' },
        { field: 'n_totalitem', headerName: 'Total', type: 'number' },
        { field: 'grupo', headerName: 'Grupo' },
        { field: 'str_referencia', headerName: 'Referência' },
        { field: 'n_codigoproduto', headerName: 'Cod Produto' },
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'por-cliente-por-loja',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {isLoading && <Loader />}
            <div id="wrapper">
                <SideBar active="VendasDetalhado" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <input
                                                        type="month"
                                                        className="form-control"
                                                        value={month}
                                                        onChange={handleMonthChange}
                                                    />
                                                </div>
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option value="DataPedido">Data Pedido</option>
                                                        <option value="DataCaixa">Data Entrada no Caixa</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary" >Filtrar</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-12">
                                                    <div style={{ height: 600, width: '100%' }}>
                                                        <DataGrid
                                                            rows={response}
                                                            columns={columns}
                                                            columnVisibilityModel={{
                                                                idcloud: false,
                                                                n_codigoloja: false,
                                                                str_codigocliente: false,
                                                                n_codigovendedor: false,
                                                            }}
                                                            pageSize={10}
                                                            autosizeOptions={DEFAULT_GRID_AUTOSIZE_OPTIONS}
                                                            rowsPerPageOptions={[10]}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(model) => setFilterModel(model)}
                                                            getRowId={(row) => row.idcloud}
                                                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                                            slots={{ toolbar: CustomToolbar }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default VendasDetalhado;