import './style.css';

const CardSimples = ({ title, text, icon, color }) => {

    return (
        <div className={"card bg-" + color}>
            <div className="card-statistic-3 text-white">
                <div className="card-icon card-icon-large"><i className={icon}></i></div>
                <div className="mb-2">
                    <p className="card-title mb-0 f-11">{title}</p>
                </div>
                <div className="row align-items-center d-flex mb-2">
                    <div className="col-12">
                        <p className="d-flex align-items-center mb-0 font-10">
                            <b>{text}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardSimples;