import Footer from "../../../components/footer/index.jsx";
import SideBar from "../../../components/sidebar/index.jsx";
import TopBar from "../../../components/topbar/index.jsx";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { api, comprasCurvaABC } from "../../../services/api.jsx";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth.jsx";
import Card from "../../../components/card/index.jsx";
import Loader from "../../../components/loader/index.jsx";
import { convertReal } from "../../../function/convertReal.jsx";
import { addLeadingZero } from "../../../function/addLeadingZero.jsx"
import { parse, isValid } from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const ComprasCurvaABC = () => {

    const { logout } = useContext(AuthContext);
    const [curva, setCurva] = useState([]);
    const [selectTop, setSelectTop] = useState(10);
    const [selectPeriodo, setSelectPeriodo] = useState(1);
    const [selectTipo, setSelectTipo] = useState(1);
    const [selectTipoInfo, setSelectTipoInfo] = useState('produto');
    const [colRelatorio, setColRelatorio] = useState('col-xl-6 col-lg-6 col-md-12');
    const [isLoading, setLoading] = useState(true);
    const [classStartDate, setClassStartDate] = useState('');
    const [styleStartDate, setStyleStartDate] = useState({ display: "none" });
    const [classEndDate, setClassEndDate] = useState('');
    const [styleEndDate, setStyleEndDate] = useState({ display: "none" });
    const [btnDisabled, setBtnDisabled] = useState(false);

    const handleSelectTop = (event) => { setSelectTop(event.target.value) }
    const handleSelectTipo = (event) => { setSelectTipo(event.target.value) }
    const handleSelectTipoInfo = (event) => { setSelectTipoInfo(event.target.value) }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }
        comprasCurva(selectPeriodo, selectTop, selectTipo).then(() => {
            setLoading(false);
        });

    }, []);

    const handlerFiltrar = () => {
        setLoading(true);
        comprasCurva(selectPeriodo, selectTop, selectTipo).then(() => {
            setLoading(false);
        });
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);

        validaDatas(event.target.value, endDate)
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);

        validaDatas(startDate, event.target.value);
    };

    const validaDatas = (startDate1, endDate1) => {
        const parsedStartDate = parse(startDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedStartDate)) {
            setClassStartDate('');
            setStyleStartDate({ display: 'none' });
            setBtnDisabled(false);
        }
        else {
            setClassStartDate('is-invalid');
            setStyleStartDate({ display: 'block' });
            setBtnDisabled(true);
        }

        const parsedEndDate = parse(endDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedEndDate)) {
            setClassEndDate('');
            setStyleEndDate({ display: 'none' });
        }
        else {
            setClassEndDate('is-invalid');
            setStyleEndDate({ display: 'block' });
        }

        if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
            setBtnDisabled(false);
        }
        else {
            setBtnDisabled(true);
        }

    }

    const comprasCurva = async (periodo, top, tipoconsulta) => {
        try {
            let datainicio, datafim, firstDay, lastDay;
            const today = new Date();
            if (periodo == 1) {

                const currentDayOfWeek = today.getDay();
                const firstDayOffset = currentDayOfWeek === 0 ? - 7 : 0 - currentDayOfWeek;
                const lastDayOffset = 6 - currentDayOfWeek;

                firstDay = new Date(today);
                firstDay.setDate(firstDay.getDate() + firstDayOffset);

                lastDay = new Date(today);
                lastDay.setDate(lastDay.getDate() + lastDayOffset);
            }
            else if (periodo == 2) {
                firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            }
            else if (periodo == 3) {
                firstDay = new Date(today.getFullYear(), 0, 1);
                lastDay = new Date(today.getFullYear(), 11, 31);
            }
            else if (periodo == 4) {
                firstDay = new Date(today.getFullYear() - 1, 0, 1);
                lastDay = new Date(today.getFullYear() - 1, 11, 31);
            }
            else if (selectPeriodo === "custom") {
                datainicio = startDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1') + ' 00:00:00';
                datafim = endDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1') + ' 23:59:59';
            }
            if (datainicio == undefined) {
                datainicio = addLeadingZero(firstDay.getMonth() + 1) + '/' + addLeadingZero(firstDay.getDate()) + '/' + firstDay.getFullYear() + ' 00:00:00';
                datafim = addLeadingZero(lastDay.getMonth() + 1) + '/' + addLeadingZero(lastDay.getDate()) + '/' + lastDay.getFullYear() + ' 23:59:59';
            }

            if (tipoconsulta == 2) {
                var tipoconsultaS = 'Consolidado';
                setColRelatorio('col-xl-12 col-lg-12 col-md-12');
            }
            else {
                var tipoConsultaS = 'PorLoja'
                setColRelatorio('col-xl-6 col-lg-6 col-md-12');
            }

            var response = await comprasCurvaABC(datainicio, datafim, top, tipoConsultaS, selectTipoInfo);
            setCurva(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const [showCustomPeriod, setShowCustomPeriod] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleSelectPeriodo = (event) => {
        const selectedPeriod = event.target.value;
        setSelectPeriodo(selectedPeriod);
        setShowCustomPeriod(selectedPeriod === "custom"); // Mostrar campos de data se a opção for "custom"
    };

    useEffect(() => {
        // Limpar campos de data quando a opção selecionada não for mais "custom"
        if (selectPeriodo !== "custom") {
            setStartDate('');
            setEndDate('');
        }
    }, [selectPeriodo]);

    return (<>
        {(isLoading) &&
            <Loader />
        }
        <div id="wrapper">
            <SideBar active="ComprasCurvaABC" />
            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">
                    <TopBar />

                    {/* CONTEUDO DA PAGINA */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3">
                                        <div className="row justify-content-center d-flex align-items-center">
                                            <div className="me-1 mt-1 col-xl-2">
                                                <select className="form-select" value={selectTipoInfo} onChange={handleSelectTipoInfo}>
                                                    <option value="produto" defaultChecked selected>Produtos</option>
                                                    <option value="fornecedor">Fornecedor</option>
                                                    <option value="grupo">Grupo</option>
                                                    <option value="subgrupo">Sub-Grupo</option>
                                                    <option value="marca">Marca</option>
                                                </select>
                                            </div>
                                            <div className="me-1 mt-1 col-xl-2">
                                                <select className="form-select" value={selectPeriodo} onChange={handleSelectPeriodo}>
                                                    <option value="1">Esta semana</option>
                                                    <option value="2">Este mês</option>
                                                    <option value="3">Este ano</option>
                                                    <option value="4">Ano Anterior</option>
                                                    <option value="custom">Período</option> {/* Adicionar a opção "Período" */}
                                                </select>
                                            </div>
                                            {/* Renderizar campos de data quando a opção for "custom" */}
                                            {showCustomPeriod && (
                                                <>
                                                    <div className="me-1 mt-1 col-xl-2">
                                                        <input
                                                            type="date"
                                                            className={"form-control " + classStartDate}
                                                            id="startDate"
                                                            name="startDate"
                                                            value={startDate}
                                                            onChange={handleStartDateChange}
                                                        />
                                                        <div className="invalid-tooltip" style={styleStartDate}>
                                                            Data inválida
                                                        </div>
                                                    </div>
                                                    <div className="me-1 mt-1 col-xl-2">
                                                        <input
                                                            type="date"
                                                            className={"form-control " + classEndDate}
                                                            id="endDate"
                                                            name="endDate"
                                                            value={endDate}
                                                            onChange={handleEndDateChange}
                                                        />
                                                        <div className="invalid-tooltip" style={styleEndDate}>
                                                            Data inválida
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="me-1 mt-1 col-xl-2">
                                                <select className="form-select" value={selectTop} onChange={handleSelectTop}>
                                                    <option defaultValue value="10">Top 10</option>
                                                    <option value="20">Top 20</option>
                                                    <option value="30">Top 30</option>
                                                    <option value="40">Top 40</option>
                                                    <option value="50">Top 50</option>
                                                    <option value="100">Top 100</option>
                                                </select>
                                            </div>
                                            <div className="me-2 mt-1 col-xl-2">
                                                <select className="form-select" value={selectTipo} onChange={handleSelectTipo}>
                                                    <option defaultValue value="1">Por Loja</option>
                                                    <option value="2">Consolidado</option>
                                                </select>
                                            </div>
                                            <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                <button onClick={handlerFiltrar} className="btn btn-primary" disabled={btnDisabled}>Filtrar</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row">
                                    {
                                        curva.map((value, index) => {
                                            let altura;
                                            if (value[0].Body.length >= 10) {
                                                altura = { height: value[0].Body.length * 30 + "px" };
                                            }
                                            else {
                                                altura = { height: "300px" };
                                            }
                                            let cor = [];
                                            value[0].Body.map((body, index) => {
                                                if (body.str_curva == 'A') {
                                                    cor.push('rgba(61, 169, 86, 0.7)');
                                                }
                                                else if (body.str_curva == 'B') {
                                                    cor.push('rgba(248, 188, 40, 0.7)');
                                                }
                                                else if (body.str_curva == 'C') {
                                                    cor.push('rgba(230, 64, 59, 0.7)');
                                                }
                                                else {
                                                    cor.push('rgba(76, 133, 241, 0.7)');
                                                }
                                            })

                                            return (
                                                <div className={colRelatorio} key={index}>
                                                    <div className="card shadow mb-4">
                                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                            <h6 className="m-0 font-weight-bold text-primary">{value[0].NomeLoja}</h6>
                                                            <div className="dropdown no-arrow">
                                                                <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample" + index} aria-expanded="true" aria-controls={"collapseWidthExample" + index}>
                                                                    <b>+ info</b>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row collapse hide" id={"collapseWidthExample" + index}>
                                                                <div className="col-xl-3 col-lg-6 col-6">
                                                                    <Card
                                                                        title='Venda'
                                                                        text={convertReal(value[0].Totalizador.TotalItemVenda, 0)}
                                                                        icon='fas fa-store'
                                                                        color="cherry"
                                                                        percent={convertReal(value[0].Totalizador.TotalItemVendaComCusto, 0)}
                                                                        tips="Total Venda com Custo"
                                                                        hint="" />
                                                                </div>
                                                                <div className="col-xl-3 col-lg-6 col-6">
                                                                    <Card
                                                                        title='Custo'
                                                                        text={convertReal(value[0].Totalizador.TotalItemCusto, 0)}
                                                                        icon='fas fa-dollar-sign'
                                                                        color="blue-dark"
                                                                        percent="&nbsp;"
                                                                        hint="" />
                                                                </div>
                                                                <div className="col-xl-3 col-lg-6 col-6">
                                                                    <Card
                                                                        title='Lucro'
                                                                        text={convertReal(value[0].Totalizador.LucroValor, 0)}
                                                                        icon='fas fa-scale-balanced'
                                                                        color="green-dark"
                                                                        percent={convertReal(value[0].Totalizador.Lucratividade, 1) + "%"}
                                                                        tips="Lucratividade"
                                                                        hint="" />
                                                                </div>
                                                                <div className="col-xl-3 col-lg-6 col-6">
                                                                    <Card
                                                                        title='Margem'
                                                                        text={convertReal(value[0].Totalizador.LucroAplicado, 1) + "%"}
                                                                        icon='fas fa-arrow-up-right-dots'
                                                                        color="orange-dark"
                                                                        percent="&nbsp;"
                                                                        hint="Margem Aplicada" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 mb-1">
                                                                <div className="col-xl-12 align-self-center text-center">
                                                                    <a className="curva a">Curva A</a>
                                                                    <a className="curva b ms-2 me-2">Curva B</a>
                                                                    <a className="curva c">Curva C</a>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                                                <div className="col-lg-12" style={altura}>
                                                                    <Bar data={
                                                                        {
                                                                            labels: value[0].Body.map((key) => key.descricao),
                                                                            datasets: [
                                                                                {
                                                                                    data: value[0].Body.map((key) => key.total),
                                                                                    backgroundColor: cor,
                                                                                },
                                                                            ],
                                                                        }
                                                                    }
                                                                        options={
                                                                            {
                                                                                maintainAspectRatio: false,
                                                                                indexAxis: 'y',
                                                                                responsive: true,
                                                                                plugins: {
                                                                                    tooltip: {
                                                                                        callbacks: {
                                                                                            label: function (context) {
                                                                                                let label = context.dataset.label || '';

                                                                                                if (label) {
                                                                                                    label += ': ';
                                                                                                }
                                                                                                if (context.parsed.x !== null) {
                                                                                                    label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.x);
                                                                                                }
                                                                                                return label;
                                                                                            },
                                                                                            title: function (tooltipItem, data) {
                                                                                                let label = value[0].Body[tooltipItem[0].dataIndex].codigo + ' - ';
                                                                                                label += tooltipItem[0].label;
                                                                                                label += '\n' + value[0].Body[tooltipItem[0].dataIndex].n_participacao.toString().replace(".", ",") + '%';
                                                                                                return label;
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                    title: {
                                                                                        display: false,
                                                                                    },
                                                                                },

                                                                            }
                                                                        } />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>)
                                        })

                                    }
                                </div>

                            </div>

                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div>
        </div></>
    )
}

export default ComprasCurvaABC;