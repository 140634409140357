import SideBar from "../../../../components/sidebar/index.jsx";
import TopBar from "../../../../components/topbar/index.jsx";
import { useContext, useEffect, useState } from "react";
import { api } from "../../../../services/api.jsx";
import { Navigate } from "react-router-dom";
import Loader from "../../../../components/loader/index.jsx";
import { convertReal } from "../../../../function/convertReal.jsx";
import { AuthContext } from "../../../../contexts/auth.jsx";
import { addLeadingZero } from "../../../../function/addLeadingZero.jsx";
import { vendasPDVForma } from "../../../../services/venda/pdv.jsx";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, SubTitle } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { colors } from '../../../../assets/colors/colors.jsx';
import ChartAnnotation from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CardSimples from "../../../../components/card-simples/index.jsx";
import { parse, isValid } from 'date-fns';
import './style.css';

ChartJS.register(ArcElement, Tooltip, Legend, SubTitle);

const PDVFormaPagamento = () => {

    const [isLoading, setLoading] = useState(true);
    const [response, setResponse] = useState({
        lojas: [{
            Body: {
                n_valor_devolvido: [],
                n_valor_recebidoliquido: [],
                n_valor_troco: [],
                n_valor_vendido: [],
                n_valor_vendido_recebidoliquido: [],
                str_forma_pgto: [],
                totais: {
                    total_devolvido: 0,
                    total_recebidoliquido: 0,
                    total_troco: 0,
                    total_vendido: 0,
                    total_vendidorecebidoliquido: 0,
                }
            }
        }],
        totalizadores: {
            n_total_devolvido: 0,
            n_total_recebidoliquido: 0,
            n_total_troco: 0,
            n_total_vendido: 0,
            n_total_vendido_recebidoliquido: 0,
        }
    });
    const [selectTipoData, setSelectTipoData] = useState(localStorage.getItem('regimeCaixa'));
    const [selectPeriodo, setSelectPeriodo] = useState(5);
    const { logout } = useContext(AuthContext);
    const [showCustomPeriod, setShowCustomPeriod] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectTipo, setSelectTipo] = useState('PorLoja');
    const [colRelatorio, setColRelatorio] = useState('col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12');
    const [height, setHeight] = useState('35vh');
    const [classStartDate, setClassStartDate] = useState('');
    const [styleStartDate, setStyleStartDate] = useState({ display: "none" });
    const [classEndDate, setClassEndDate] = useState('');
    const [styleEndDate, setStyleEndDate] = useState({ display: "none" });
    const [btnDisabled, setBtnDisabled] = useState(false);

    const handleSelectPeriodo = (event) => {
        const selectedPeriod = event.target.value;
        setSelectPeriodo(selectedPeriod);
        setShowCustomPeriod(selectedPeriod === "custom"); // Mostrar campos de data se a opção for "custom"
    };

    const handleSelectTipo = (event) => { setSelectTipo(event.target.value) }

    const handleSelectTipoData = (e) => {
        localStorage.setItem('regimeCaixa', e.target.value);
        return setSelectTipoData(e.target.value);
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token[localStorage.getItem('active')]}`;
        }
        else {
            Navigate("/login");
        }
        const regimeCaixa = localStorage.getItem('regimeCaixa');
        if (!regimeCaixa) {
            localStorage.setItem('regimeCaixa', 'caixa');
            setSelectTipoData('caixa');
        }

        vendasPDVFormaF(selectPeriodo, selectTipo).then(() => {
            setLoading(false);
        });

    }, []);

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);

        validaDatas(startDate, event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);

        validaDatas(event.target.value, endDate)
    };

    const validaDatas = (startDate1, endDate1) => {
        const parsedStartDate = parse(startDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedStartDate)) {
            setClassStartDate('');
            setStyleStartDate({ display: 'none' });
            setBtnDisabled(false);
        }
        else {
            setClassStartDate('is-invalid');
            setStyleStartDate({ display: 'block' });
            setBtnDisabled(true);
        }

        const parsedEndDate = parse(endDate1, 'yyyy-MM-dd', new Date());
        if (isValid(parsedEndDate)) {
            setClassEndDate('');
            setStyleEndDate({ display: 'none' });
        }
        else {
            setClassEndDate('is-invalid');
            setStyleEndDate({ display: 'block' });
        }

        if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
            setBtnDisabled(false);
        }
        else {
            setBtnDisabled(true);
        }

    }

    const handlerFiltrar = () => {
        setLoading(true);
        vendasPDVFormaF(selectPeriodo, selectTipo).then(() => {
            setLoading(false);
        });
    }

    const vendasPDVFormaF = async (periodo, tipoConsulta) => {
        try {
            let datainicio, datafim, firstDay, lastDay;
            const today = new Date();
            if (periodo == 1) {

                const currentDayOfWeek = today.getDay();
                const firstDayOffset = currentDayOfWeek === 0 ? - 7 : 0 - currentDayOfWeek;
                const lastDayOffset = 6 - currentDayOfWeek;

                firstDay = new Date(today);
                firstDay.setDate(firstDay.getDate() + firstDayOffset);

                lastDay = new Date(today);
                lastDay.setDate(lastDay.getDate() + lastDayOffset);
            }
            else if (periodo == 2) {
                firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            }
            else if (periodo == 3) {
                firstDay = new Date(today.getFullYear(), 0, 1);
                lastDay = new Date(today.getFullYear(), 11, 31);
            }
            else if (periodo == 4) {
                firstDay = new Date(today.getFullYear() - 1, 0, 1);
                lastDay = new Date(today.getFullYear() - 1, 11, 31);
            }
            else if (periodo == 5) {
                firstDay = today;
                lastDay = today;
            }
            else if (selectPeriodo === "custom") {
                datainicio = startDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1') + ' 00:00:00';
                datafim = endDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1') + ' 23:59:59';
            }
            if (datainicio == undefined) {
                datainicio = addLeadingZero(firstDay.getMonth() + 1) + '/' + addLeadingZero(firstDay.getDate()) + '/' + firstDay.getFullYear() + ' 00:00:00';
                datafim = addLeadingZero(lastDay.getMonth() + 1) + '/' + addLeadingZero(lastDay.getDate()) + '/' + lastDay.getFullYear() + ' 23:59:59';
            }

            var response = await vendasPDVForma(datainicio, datafim, tipoConsulta);
            if (selectTipo == 'Consolidado' || response.data.lojas.length == 1) {
                setColRelatorio('col-12');
                setHeight('60vh');
            }
            else {
                setColRelatorio('col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12');
                setHeight('35vh')
            }
            setResponse(response.data);
        }
        catch (err) {
            if (err.response.status === 401) {
                logout();
            }
        }
    }

    const mapDescricaoToColorAuto = (descricao, coresPadrao, alphaBorder, alphaBackground) => {
        // Se a descrição já foi mapeada para uma cor, retorna a cor correspondente
        if (mapDescricaoToColorAuto.descricaoCores[descricao]) {
            return mapDescricaoToColorAuto.descricaoCores[descricao];
        }

        // Se a descrição não tem uma cor mapeada, atribui uma cor do array de cores padrão
        const cor = coresPadrao[mapDescricaoToColorAuto.index % coresPadrao.length];
        const corComAlpha = cor.replace(')', `, ${alphaBorder})`);
        const backgroundColor = cor.replace(')', `, ${alphaBackground})`);
        mapDescricaoToColorAuto.descricaoCores[descricao] = { borderColor: corComAlpha, backgroundColor };
        mapDescricaoToColorAuto.index++;
        return { borderColor: corComAlpha, backgroundColor };
    };

    mapDescricaoToColorAuto.index = 0;
    mapDescricaoToColorAuto.descricaoCores = {};

    const getDatasets = () => {
        if (selectTipoData === 'competencia') {
            return [
                {
                    label: 'Vendido com devolução',
                    data: response.lojas.map(item => item.Body.totais.total_vendido + Math.abs(item.Body.totais.total_devolvido)),
                    borderColor: 'rgb(54, 162, 235)',
                    backgroundColor: 'rgba(54, 162, 235, 0.7)',
                    borderWidth: 2,
                    borderRadius: 5,
                },
                {
                    label: 'Total',
                    data: response.lojas.map(item => 0), // Ajuste conforme necessário
                },
            ];
        }
        else {
            return [
                {
                    label: 'Vendido com devolução',
                    data: response.lojas.map(item => item.Body.totais.total_vendido + Math.abs(item.Body.totais.total_devolvido)),
                    borderColor: 'rgb(54, 162, 235)',
                    backgroundColor: 'rgba(54, 162, 235, 0.7)',
                    borderWidth: 2,
                    borderRadius: 5,
                },
                {
                    label: 'Recebido Líquido',
                    data: response.lojas.map(item => item.Body.totais.total_recebidoliquido),
                    borderColor: 'rgba(255, 159, 64, 1)',
                    backgroundColor: 'rgba(255, 159, 64, 0.7)',
                    borderWidth: 2,
                    borderRadius: 5,
                },
                {
                    label: 'Total',
                    data: response.lojas.map(item => 0), // Ajuste conforme necessário
                },
            ];
        }
    };

    const createRows = (Body) => {
        return Body.str_forma_pgto.map((formaPgto, index) => ({
            formaPgto,
            troco: Body.n_valor_troco[index],
            vendido: Body.n_valor_vendido[index],
            devolvido: Body.n_valor_devolvido[index],
            recebidoLiquido: Body.n_valor_recebidoliquido[index],
            vendidoRecebidoLiquido: Body.n_valor_vendido_recebidoliquido[index],
        }));
    };

    const calculateTotals = (rows) => {
        return rows.reduce(
            (totals, row) => {
                totals.troco += row.troco;
                totals.vendido += row.vendido;
                totals.devolvido += row.devolvido;
                totals.recebidoLiquido += row.recebidoLiquido;
                totals.vendidoRecebidoLiquido += row.vendidoRecebidoLiquido;
                return totals;
            },
            {
                troco: 0,
                vendido: 0,
                devolvido: 0,
                recebidoLiquido: 0,
                vendidoRecebidoLiquido: 0,
            }
        );
    };

    const calculateTotalsByFormaPagamento = (lojasData) => {
        const totalsByFormaPagamento = {};

        lojasData.forEach((loja) => {
            loja.Body.str_forma_pgto.forEach((formaPgto, index) => {
                if (!totalsByFormaPagamento[formaPgto]) {
                    totalsByFormaPagamento[formaPgto] = {
                        troco: 0,
                        vendido: 0,
                        devolvido: 0,
                        recebidoLiquido: 0,
                        vendidoRecebidoLiquido: 0,
                    };
                }

                totalsByFormaPagamento[formaPgto].troco += loja.Body.n_valor_troco[index];
                totalsByFormaPagamento[formaPgto].vendido += loja.Body.n_valor_vendido[index];
                totalsByFormaPagamento[formaPgto].devolvido += loja.Body.n_valor_devolvido[index];
                totalsByFormaPagamento[formaPgto].recebidoLiquido += loja.Body.n_valor_recebidoliquido[index];
                totalsByFormaPagamento[formaPgto].vendidoRecebidoLiquido += loja.Body.n_valor_vendido_recebidoliquido[index];
            });
        });

        return totalsByFormaPagamento;
    };

    const calculateGrandTotal = (totalsByFormaPagamento) => {
        return Object.values(totalsByFormaPagamento).reduce((grandTotal, totals) => {
            grandTotal.troco += totals.troco;
            grandTotal.vendido += totals.vendido;
            grandTotal.devolvido += totals.devolvido;
            grandTotal.recebidoLiquido += totals.recebidoLiquido;
            grandTotal.vendidoRecebidoLiquido += totals.vendidoRecebidoLiquido;
            return grandTotal;
        }, {
            troco: 0,
            vendido: 0,
            devolvido: 0,
            recebidoLiquido: 0,
            vendidoRecebidoLiquido: 0,
        });
    };

    const LojaTable = ({ loja }) => {
        const rows = createRows(loja.Body);
        const totals = calculateTotals(rows);
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-fixed-column">
                        <thead>
                            <tr>
                                <th colSpan="6" className="text-center fs-4 p-1 table-primary">{loja.NomeLoja}</th>
                            </tr>
                            <tr className="table-secondary">
                                <th style={{ width: "30vh" }}>Forma de Pagamento</th>
                                <th style={{ width: "20vh" }}>Troco</th>
                                <th style={{ width: "20vh" }}>Vendido</th>
                                <th style={{ width: "20vh" }}>Devolvido</th>
                                <th style={{ width: "20vh" }}>Recebido Líquido</th>
                                <th style={{ width: "20vh" }}>Vendido Recebido Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <th className="table-secondary">{row.formaPgto}</th>
                                    <td>{convertReal(row.troco, 2)}</td>
                                    <td>{convertReal(row.vendido, 2)}</td>
                                    <td>{convertReal(row.devolvido, 2)}</td>
                                    <td>{convertReal(row.recebidoLiquido, 2)}</td>
                                    <td>{convertReal(row.vendidoRecebidoLiquido, 2)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className="table-secondary">
                                <th>TOTAL</th>
                                <th>{convertReal(totals.troco, 2)}</th>
                                <th>{convertReal(totals.vendido, 2)}</th>
                                <th>{convertReal(totals.devolvido, 2)}</th>
                                <th>{convertReal(totals.recebidoLiquido, 2)}</th>
                                <th>{convertReal(totals.vendidoRecebidoLiquido, 2)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </>
        );
    };

    const ConsolidadoPorFormaPagamento = ({ lojasData }) => {
        const totalsByFormaPagamento = calculateTotalsByFormaPagamento(lojasData);
        const grandTotal = calculateGrandTotal(totalsByFormaPagamento);
        return (
            <>

                <div className="table-responsive">
                    <table className="table table-fixed-column">
                        <thead>
                            <tr>
                                <th colSpan="6" className="text-center fs-4 p-1 table-success">TODAS AS LOJAS</th>
                            </tr>
                            <tr className="table-secondary">
                                <th style={{ width: "30vh" }}>Forma de Pagamento</th>
                                <th style={{ width: "20vh" }}>Troco</th>
                                <th style={{ width: "20vh" }}>Vendido</th>
                                <th style={{ width: "20vh" }}>Devolvido</th>
                                <th style={{ width: "20vh" }}>Recebido Líquido</th>
                                <th style={{ width: "20vh" }}>Vendido Recebido Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(totalsByFormaPagamento).map(([formaPgto, totals], index) => (
                                <tr key={index}>
                                    <th className="table-secondary">{formaPgto}</th>
                                    <td>{convertReal(totals.troco, 2)}</td>
                                    <td>{convertReal(totals.vendido, 2)}</td>
                                    <td>{convertReal(totals.devolvido, 2)}</td>
                                    <td>{convertReal(totals.recebidoLiquido, 2)}</td>
                                    <td>{convertReal(totals.vendidoRecebidoLiquido, 2)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className="table-secondary">
                                <th>TOTAL GERAL</th>
                                <th>{convertReal(grandTotal.troco, 2)}</th>
                                <th>{convertReal(grandTotal.vendido, 2)}</th>
                                <th>{convertReal(grandTotal.devolvido, 2)}</th>
                                <th>{convertReal(grandTotal.recebidoLiquido, 2)}</th>
                                <th>{convertReal(grandTotal.vendidoRecebidoLiquido, 2)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </>
        );
    };

    return (
        <>
            {(isLoading) &&
                <Loader />
            }
            <div id="wrapper">
                <SideBar active="PDVFormaPagamento" />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar />

                        {/* CONTEUDO DA PAGINA */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <div className="row justify-content-center d-flex align-items-center">
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <select className="form-select" defaultValue={selectTipoData} onChange={handleSelectTipoData}>
                                                        <option value="caixa">Regime de caixa</option>
                                                        <option value="competencia">Regime Competência</option>
                                                    </select>
                                                </div>
                                                <div className="me-1 mt-1 col-xl-2">
                                                    <select className="form-select" defaultValue={selectPeriodo} onChange={handleSelectPeriodo}>
                                                        <option value="5">Hoje</option>
                                                        <option value="1">Esta semana</option>
                                                        <option value="2">Este mês</option>
                                                        <option value="3">Este ano</option>
                                                        <option value="4">Ano Anterior</option>
                                                        <option value="custom">Período</option> {/* Adicionar a opção "Período" */}
                                                    </select>
                                                </div>
                                                {/* Renderizar campos de data quando a opção for "custom" */}
                                                {showCustomPeriod && (
                                                    <>
                                                        <div className="me-1 mt-1 col-xl-2">
                                                            <input
                                                                type="date"
                                                                className={"form-control " + classStartDate}
                                                                id="startDate"
                                                                name="startDate"
                                                                value={startDate}
                                                                onChange={handleStartDateChange}
                                                            />
                                                            <div className="invalid-tooltip" style={styleStartDate}>
                                                                Data inválida
                                                            </div>
                                                        </div>
                                                        <div className="me-1 mt-1 col-xl-2">
                                                            <input
                                                                type="date"
                                                                className={"form-control " + classEndDate}
                                                                id="endDate"
                                                                name="endDate"
                                                                value={endDate}
                                                                onChange={handleEndDateChange}
                                                            />
                                                            <div className="invalid-tooltip" style={styleEndDate}>
                                                                Data inválida
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="me-2 mt-1 col-xl-2">
                                                    <select className="form-select" value={selectTipo} onChange={handleSelectTipo}>
                                                        <option defaultValue value="PorLoja">Por Loja</option>
                                                        <option value="Consolidado">Consolidado</option>
                                                    </select>
                                                </div>
                                                <div className="me-2 mt-1 col-xl-1 col-12 d-grid gap-2">
                                                    <button onClick={handlerFiltrar} className="btn btn-primary" disabled={btnDisabled}>Filtrar</button>
                                                </div>
                                                <div className="me-2 mt-1  dropdown no-arrow col-xl-1 col-12 d-grid gap-2">
                                                    <a className="dropdown-toggle pointer btn btn-primary text-white" role="button" data-bs-toggle="collapse" data-bs-target={"#collapseWidthExample"} aria-expanded="true" aria-controls={"collapseWidthExample"}>
                                                        <b>+ info</b>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body collapse show" id={"collapseWidthExample"}>
                                            <div className="row justify-content-center">
                                                <div className="col-xl-2 col-lg-6 col-12">
                                                    <CardSimples
                                                        title='Vendido'
                                                        text={'+' + convertReal(response.totalizadores.n_total_vendido, 0)}
                                                        icon='fas fa-store'
                                                        color="success" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-12">
                                                    <CardSimples
                                                        title='Devolvido'
                                                        text={convertReal(response.totalizadores.n_total_devolvido, 0)}
                                                        icon='fas fa-store'
                                                        color="warning" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-12">
                                                    <CardSimples
                                                        title='Sub-Total'
                                                        text={'=' + convertReal(response.totalizadores.n_total_vendido + response.totalizadores.n_total_devolvido, 0)}
                                                        icon='fas fa-store'
                                                        color="primary" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-12">
                                                    <CardSimples
                                                        title='Recebido Líquido'
                                                        text={'+' + convertReal(response.totalizadores.n_total_recebidoliquido, 0)}
                                                        icon='fas fa-store'
                                                        color="warning" />
                                                </div>
                                                <div className="col-xl-2 col-lg-6 col-12">
                                                    <CardSimples
                                                        title='Total Líquido'
                                                        text={'=' + convertReal(response.totalizadores.n_total_vendido_recebidoliquido, 0)}
                                                        icon='fas fa-store'
                                                        color="primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            response.lojas.map((res, key) => {
                                                const bColor = res.Body.str_forma_pgto.map(descricao => mapDescricaoToColorAuto(descricao, colors, 1, 0.6)); // Sem transparência para a cor da borda e o fundo
                                                let subTotal;

                                                if (selectTipoData == 'compenetencia') {
                                                    var totalGeral = convertReal(res.Body.totais.total_vendido + Math.abs(res.Body.totais.total_devolvido), 2);
                                                    subTotal = res.Body.n_valor_vendido.map((valor, index) => valor - res.Body.n_valor_devolvido[index]);

                                                }
                                                else if (selectTipoData == 'caixa') {
                                                    var totalGeral = convertReal(res.Body.totais.total_vendidorecebidoliquido, 2);
                                                    subTotal = res.Body.n_valor_vendido_recebidoliquido;
                                                }
                                                else {
                                                    var totalGeral = convertReal(res.Body.totais.total_vendidorecebidoliquido, 2);
                                                    subTotal = res.Body.n_valor_vendido_recebidoliquido;
                                                }


                                                return (<>
                                                    <div className={colRelatorio} key={key}>
                                                        <div className="card shadow mb-4">
                                                            <div className="card-header">
                                                                <b>{res.NomeLoja}</b>
                                                            </div>
                                                            <div className="card-body mb-3">
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        <p>Total Geral R$ {totalGeral}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12" style={{ height: height, maxHeight: '300px' }}>
                                                                        <Doughnut plugins={[ChartDataLabels]} data={{
                                                                            labels: res.Body.str_forma_pgto,
                                                                            datasets: [
                                                                                {
                                                                                    data: subTotal,
                                                                                    borderColor: bColor.map(color => color.borderColor),
                                                                                    backgroundColor: bColor.map(color => color.backgroundColor),
                                                                                    borderWidth: 1
                                                                                },
                                                                            ],
                                                                        }}
                                                                            options={
                                                                                {
                                                                                    maintainAspectRatio: false,
                                                                                    plugins: {
                                                                                        title: {
                                                                                            display: false
                                                                                        },
                                                                                        subtitle: {
                                                                                            display: false
                                                                                        },
                                                                                        legend: {
                                                                                            position: "right"
                                                                                        },
                                                                                        tooltip: {
                                                                                            callbacks: {
                                                                                                label: function (context) {
                                                                                                    const index = context.dataIndex;
                                                                                                    let retorno;
                                                                                                    if (selectTipoData == 'competencia') {
                                                                                                        retorno = [
                                                                                                            `Vendido: R$ ${convertReal(res.Body.n_valor_vendido[index], 0)}`,
                                                                                                            `Devolução: R$ ${convertReal(res.Body.n_valor_devolvido[index], 0)}`,
                                                                                                            `Total: R$ ${convertReal(res.Body.n_valor_vendido[index] - Math.abs(res.Body.n_valor_devolvido[index]), 0)}`,
                                                                                                        ]
                                                                                                    }
                                                                                                    else if (selectTipoData == 'caixa') {
                                                                                                        retorno = [
                                                                                                            `Vendido: R$ ${convertReal(res.Body.n_valor_vendido[index], 0)}`,
                                                                                                            `Devolução: R$ ${convertReal(res.Body.n_valor_devolvido[index], 0)}`,
                                                                                                            `Sub-total: R$ ${convertReal(res.Body.n_valor_vendido[index] - Math.abs(res.Body.n_valor_devolvido[index]), 0)}`,
                                                                                                            `Recebido Líquido: R$ ${convertReal(res.Body.n_valor_recebidoliquido[index], 0)}`,
                                                                                                            `Total: R$ ${convertReal(res.Body.n_valor_vendido_recebidoliquido[index], 0)}`,
                                                                                                        ]
                                                                                                    }
                                                                                                    return retorno;
                                                                                                },
                                                                                            },
                                                                                        },
                                                                                        datalabels: {
                                                                                            formatter: (value, ctx) => {
                                                                                                const sum = ctx.dataset.data.reduce((acc, data) => acc + data, 0);
                                                                                                const percentage = ((value * 100) / sum).toFixed(0);
                                                                                                if (percentage <= 1) {
                                                                                                    return ''
                                                                                                }
                                                                                                else {
                                                                                                    return percentage + '%'
                                                                                                }
                                                                                            },
                                                                                            color: '#222',
                                                                                            labels: {
                                                                                                title: { font: { size: '14', weight: 'bold' } }
                                                                                            }
                                                                                        },
                                                                                    },
                                                                                }
                                                                            } />
                                                                    </div>
                                                                </div>

                                                            </div >
                                                        </div >
                                                    </div>
                                                </>)
                                            })
                                        }


                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card shadow mb-4">
                                                <div className="card-body mb-3" style={{ height: '70vh', maxHeight: '200px' }}>
                                                    <Bar plugins={[ChartDataLabels, ChartAnnotation]}
                                                        options={
                                                            {
                                                                plugins: {
                                                                    title: {
                                                                        display: false,
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'top',
                                                                        labels: {
                                                                            filter: (legendItem, chartData) => {
                                                                                return legendItem.datasetIndex !== chartData.datasets.length - 1; // Oculta o último dataset ('Total')
                                                                            },
                                                                        },
                                                                    },
                                                                    tooltip: {
                                                                        mode: 'index',
                                                                        intersect: false,
                                                                        filter: (tooltipItem) => {
                                                                            return tooltipItem.dataset.label !== 'Total';
                                                                        },
                                                                        callbacks: {
                                                                            footer: (tooltipItems) => {
                                                                                const index = tooltipItems[0].dataIndex;
                                                                                let retorno;

                                                                                if (selectTipoData == 'competencia') {
                                                                                    retorno = [
                                                                                        //`Troco: R$ ${convertReal(response.lojas[index].Body.totais.total_troco, 2)}`,
                                                                                        `Vendido: R$ ${convertReal(response.lojas[index].Body.totais.total_vendido, 2)}`,
                                                                                        `Devolução: R$ ${convertReal(response.lojas[index].Body.totais.total_devolvido, 2)}`,
                                                                                        `Vendido com devolução: R$ ${convertReal(response.lojas[index].Body.totais.total_vendido - Math.abs(response.lojas[index].Body.totais.total_devolvido), 2)}`,
                                                                                    ]
                                                                                }
                                                                                else if (selectTipoData == 'caixa') {
                                                                                    retorno = [
                                                                                        //`Troco: R$ ${convertReal(response.lojas[index].Body.totais.total_troco, 2)}`,
                                                                                        `Vendido: R$ ${convertReal(response.lojas[index].Body.totais.total_vendido, 2)}`,
                                                                                        `Devolução: R$ ${convertReal(response.lojas[index].Body.totais.total_devolvido, 2)}`,
                                                                                        `Sub-total: R$ ${convertReal(response.lojas[index].Body.totais.total_vendido - Math.abs(response.lojas[index].Body.totais.total_devolvido), 2)}`,
                                                                                        `Recebido Líquido: R$ ${convertReal(response.lojas[index].Body.totais.total_recebidoliquido, 2)}`,
                                                                                        `Vendido recebido: R$ ${convertReal(response.lojas[index].Body.totais.total_vendidorecebidoliquido, 2)}`,
                                                                                    ]
                                                                                }
                                                                                return retorno;
                                                                            },
                                                                        },
                                                                    },
                                                                    datalabels: {
                                                                        color: '#222',
                                                                        font: {
                                                                            size: 12, // Defina o tamanho da fonte desejado
                                                                            weight: 'bold', // Defina o peso da fonte como negrito
                                                                        },
                                                                        textAlign: 'center',

                                                                        anchor: 'center', // Alinhamento do texto no centro da barra
                                                                        align: 'center',
                                                                        formatter: (value, context) => {
                                                                            const datasetArray = [];
                                                                            const datasetIndex = context.datasetIndex;
                                                                            const chartData = context.chart.data;
                                                                            const datasets = chartData.datasets;
                                                                            let sum = 0;

                                                                            context.chart.data.datasets.forEach((dataset) => {
                                                                                if (dataset.data[context.dataIndex] != undefined) {
                                                                                    datasetArray.push(dataset.data[context.dataIndex]);
                                                                                }
                                                                            });

                                                                            datasets.forEach(dataset => {
                                                                                sum += dataset.data[context.dataIndex];
                                                                            });

                                                                            const percentage = Math.round((value / sum) * 100) + '%';
                                                                            if (percentage == '0%') {
                                                                                if (context.datasetIndex === datasetArray.length - 1) {
                                                                                    return 'Total: ' + convertReal(sum, 0) + '\n\n';
                                                                                }
                                                                                else {
                                                                                    return ''
                                                                                }
                                                                            }
                                                                            else {
                                                                                return percentage;
                                                                            }
                                                                        },
                                                                    },

                                                                },
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    x: {
                                                                        stacked: true,
                                                                    },
                                                                    y: {
                                                                        stacked: true,
                                                                    },
                                                                },
                                                            }
                                                        }
                                                        data={
                                                            {
                                                                labels: response.lojas.map(item => item.NomeLoja),
                                                                datasets: getDatasets()
                                                            }
                                                        } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card shadow mb-4">
                                                <div className="card-body mb-3">
                                                    {response.lojas.map((loja) => (
                                                        <LojaTable key={loja.NomeLoja} loja={loja} />
                                                    ))}
                                                    {(selectTipo == 'PorLoja') && <ConsolidadoPorFormaPagamento lojasData={response.lojas} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM CONTEUDO DA PAGINA */}

                    </div>
                </div>
            </div >
        </>
    )
}

export default PDVFormaPagamento;