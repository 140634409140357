import { useContext, useEffect, useState } from 'react';
import './style.css';
import { AuthContext } from '../../contexts/auth';
import { useLocation } from 'react-router-dom';

const Login = () => {

    const { login, error, setError } = useContext(AuthContext);
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const Femail = urlParams.get('email');
        if (!Femail === '') {
            setEmail(Femail.trim());
            document.getElementById('senha').focus();
        }
    }, []);

    const handlerLogin = (e) => {
        e.preventDefault();
        login(email, senha);
    }

    const handlerKeyDownEmail = (e) => {
        setError('');
        var k = e.keyCode;
        if (k === 13) {
            e.preventDefault();
            document.getElementById('senha').focus();
        }
    }
    const displayError = !error == true ? 'alert alert-danger visually-hidden' : 'alert alert-danger';

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-register-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Bem-vindo de volta!</h1>
                                        </div>
                                        <div className={displayError} role="alert">
                                            {error}
                                        </div>
                                        <form className="user" onSubmit={handlerLogin}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="email"
                                                    aria-describedby="emailHelp"
                                                    style={{textTransform: 'lowercase'}}
                                                    placeholder="Digite seu e-mail..."
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    onKeyDown={(e) => handlerKeyDownEmail(e)}
                                                    value={email} />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    className="form-control form-control-user"
                                                    id="senha"
                                                    placeholder="Digite sua senha"
                                                    onChange={(e) => setSenha(e.target.value)}
                                                    onKeyDown={() => {setError('')}}
                                                    value={senha} />
                                            </div>
                                            <button type='submit' className="btn btn-primary btn-user btn-block">
                                                Entrar
                                            </button>
                                            <div className="text-center mt-3">
                                                <a className="small" href="/cadastro">Não possui uma conta? Faça seu cadastro!</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;